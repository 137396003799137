import { PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { Cached } from '@coris-ts/helpers/utilities'
import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import {
  provideDefaultArrayNetworkDataSource,
  provideDefaultNetworkDataSource
} from '@coris-ts/repository/data-source/network.data-source'
import { DeleteInteractor } from '@coris-ts/repository/interactor/delete.interactor'
import { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import {
  ArrayMapper,
  PaginationOffsetLimitMapper,
  VoidMapper
} from '@coris-ts/repository/mapper/mapper'
import { type GetRepository, type Repository } from '@coris-ts/repository/repository'
import { GetRepositoryMapper, PutRepositoryMapper } from '@coris-ts/repository/repository-mapper'
import {
  SingleGetDataSourceRepository,
  SinglePutDataSourceRepository
} from '@coris-ts/repository/single-data-source.repository'
import {
  provideVoidNetworkDataSourceRepository
} from '@coris-ts/repository/single-void-data-source.repository'
import { BulkTaskIdEntity } from './data/entities/bulk-task-id.entity'
import { CogsUpdateStatusEntity } from './data/entities/cogs-update-status.entity'
import { type ListingReportEntity } from './data/entities/listing.entity'
import { type OrderEntity } from './data/entities/order.entity'
import { ProductCogsEntity } from './data/entities/product-cogs.entity'
import {
  type ProductEntity,
  type ProductReportEntity,
  type SKUProductReportEntity
} from './data/entities/product.entity'
import { CreateCogsPeriodInteractor } from './domain/interactors/create-cogs-period.interactor'
import { GetCogsManagementInteractor } from './domain/interactors/get-cogs-management.interactor'
import { GetCogsUpdateStatusInteractor } from './domain/interactors/get-cogs-update-status.interactor'
import { GetListingReportsInteractor } from './domain/interactors/get-listing-reports.interactor'
import { GetOrdersInteractor } from './domain/interactors/get-orders.interactor'
import { GetProductCogsInteractor } from './domain/interactors/get-product-cogs.interactor'
import { GetProductProductsInteractor } from './domain/interactors/get-product-products.interactor'
import { GetProductReportsInteractor } from './domain/interactors/get-product-reports.interactor'
import { GetSkuProductReportsInteractor } from './domain/interactors/get-sku-product-reports.interactor'
import { MarkCogsUpdateAsViewedInteractor } from './domain/interactors/mark-cogs-update-as-viewed.interactor'
import { ReconcileCogsInteractor } from './domain/interactors/reconcile-cogs.interactor'
import { UpdateProductCogsHistoricInteractor } from './domain/interactors/update-product-cogs-historic.interactor'
import {
  CogsUpdateStatusEntityToCogsUpdateStatusMapper
} from './domain/mappers/cogs-update-status.mappers'
import { CogsEntityToCogsMapper, CogsTypeEntityToCogsTypeMapper } from './domain/mappers/cogs.mappers'
import { ListingReportEntityToListingReportMapper } from './domain/mappers/listing-reports.mappers'
import { ListingEntityToListingMapper } from './domain/mappers/listing.mappers'
import { OrderEntityToOrderMapper } from './domain/mappers/order.mappers'
import { ProductCogsEntityToProductCogsMapper } from './domain/mappers/product-cogs.mappers'
import {
  CogsManagementEntityToCogsManagementMapper
} from './domain/mappers/cogs-management.mappers'
import { ProductReportEntityToProductReportMapper } from './domain/mappers/product-report.mappers'
import { ProductEntityToProductMapper } from './domain/mappers/product.mappers'
import {
  SKUProductReportEntityToSKUProductReportMapper
} from './domain/mappers/sku-product-reports.mappers'
import { BulkTaskIdToStringMapper } from './domain/mappers/task-id.mappers'
import { type CogsUpdateStatus } from './domain/models/cogs-update-status'
import { type ListingReport } from './domain/models/listing'
import { type Order } from './domain/models/order'
import { type Product, type ProductReport, type SKUProductReport } from './domain/models/product'
import { type ProductCogs } from './domain/models/product-cogs'
import { GetAdsInteractor } from './domain/interactors/get-ads.interactor'
import { type Ad } from './domain/models/ad'
import { type AdEntity } from './data/entities/ad.entity'
import { AdEntityToAdMapper } from './domain/mappers/ad.mappers'
import { GetAdsTotalsInteractor } from './domain/interactors/get-ads-totals.interactor'
import { type AdsTotals } from './domain/models/ads-totals'
import { AdsTotalsEntity } from './data/entities/ads-totals.entity'
import { AdsTotalsEntityToAdsTotalsMapper } from './domain/mappers/ads-totals.mappers'
import { type CogsManagement } from './domain/models/cogs-management'
import { type CogsManagementEntity } from './data/entities/cogs-management.entity'
import { UpdateCogsInteractor } from './domain/interactors/export-cogs.interactor'
import { provideStringNetworkDataSourceRepository } from '@coris-ts/repository/single-string-data-source.repository'
import { ExportCogsManagementInteractor } from './domain/interactors/export-cogs-management.interactor'
import { ImportCogsManagementInteractor } from './domain/interactors/import-cogs-management.interactor'
import { GetCogsSummaryInteractor } from './domain/interactors/get-cogs-summary.interactor'
import { type CogsSummary } from './domain/models/cogs-summary'
import { CogsSummaryEntity } from './data/entities/cogs-summary.entity'
import { CogsSummaryEntityToCogsSummaryMapper } from './domain/mappers/cogs-summary.mappers'

export interface ProductComponent {
  provideCreateCogsPeriod: () => CreateCogsPeriodInteractor
  provideGetAds: () => GetAdsInteractor
  provideGetAdsTotals: () => GetAdsTotalsInteractor
  provideGetListingReports: () => GetListingReportsInteractor
  provideGetOrders: () => GetOrdersInteractor
  provideGetProductCogs: () => GetProductCogsInteractor
  provideGetCogsManagement: () => GetCogsManagementInteractor
  provideExportCogsManagement: () => ExportCogsManagementInteractor
  provideImportCogsManagement: () => ImportCogsManagementInteractor
  provideGetCogsSummary: () => GetCogsSummaryInteractor
  provideUpdateCogs: () => UpdateCogsInteractor
  provideGetSkuProductReports: () => GetSkuProductReportsInteractor
  provideGetProductReports: () => GetProductReportsInteractor
  provideReconcileCogs: () => ReconcileCogsInteractor
  provideUpdateProductCogsHistoricInteractor: () => UpdateProductCogsHistoricInteractor
  provideGetCogsUpdateStatus: () => GetCogsUpdateStatusInteractor
  provideGetProductProducts: () => GetProductProductsInteractor
  provideMarkCogsUpdateAsViewed: () => MarkCogsUpdateAsViewedInteractor
}

export class ProductDefaultModule implements ProductComponent {
  private readonly productMapper = new ProductEntityToProductMapper(
    new ListingEntityToListingMapper(),
    new CogsEntityToCogsMapper(),
    new CogsTypeEntityToCogsTypeMapper()
  )

  constructor (private readonly apiRequestService: ApiRequestService) {
  }

  @Cached()
  private getPaginatedAdsRepository(): GetRepositoryMapper<PaginationOffsetLimit<AdEntity>, PaginationOffsetLimit<Ad>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<AdEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new AdEntityToAdMapper()
      )
    )
  }

  @Cached()
  private getAdsTotalsRepository(): GetRepositoryMapper<AdsTotalsEntity, AdsTotals> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      AdsTotalsEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new AdsTotalsEntityToAdsTotalsMapper()
    )
  }

  @Cached()
  private getListingReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ListingReportEntity>, PaginationOffsetLimit<ListingReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ListingReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ListingReportEntityToListingReportMapper()
      )
    )
  }

  @Cached()
  private getOrdersRepository(): GetRepositoryMapper<PaginationOffsetLimit<OrderEntity>, PaginationOffsetLimit<Order>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<OrderEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new OrderEntityToOrderMapper()
      )
    )
  }

  @Cached()
  private getCogsManagementRepository(): GetRepositoryMapper<PaginationOffsetLimit<CogsManagementEntity>, PaginationOffsetLimit<CogsManagement>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<CogsManagementEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new CogsManagementEntityToCogsManagementMapper(
          this.productMapper
        )
      )
    )
  }

  @Cached()
  private getCogsSummaryRepository(): GetRepositoryMapper<CogsSummaryEntity, CogsSummary> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      CogsSummaryEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new CogsSummaryEntityToCogsSummaryMapper()
    )
  }

  @Cached()
  private getSKUProductReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<SKUProductReportEntity>, PaginationOffsetLimit<SKUProductReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<SKUProductReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new SKUProductReportEntityToSKUProductReportMapper(
          this.productMapper
        )
      )
    )
  }

  @Cached()
  private getProductReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ProductReportEntity>, PaginationOffsetLimit<ProductReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ProductReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ProductReportEntityToProductReportMapper(
          new ListingEntityToListingMapper(),
          new CogsEntityToCogsMapper(),
          new CogsTypeEntityToCogsTypeMapper()
        )
      )
    )
  }

  @Cached()
  private getProductCogsRepository(): GetRepositoryMapper<ProductCogsEntity, ProductCogs> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      ProductCogsEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ProductCogsEntityToProductCogsMapper(
        this.productMapper,
        new CogsEntityToCogsMapper()
      )
    )
  }

  @Cached()
  private getVoidRepository(): Repository<void> {
    return provideVoidNetworkDataSourceRepository(this.apiRequestService)
  }

  @Cached()
  private getStringRepository(): Repository<string> {
    return provideStringNetworkDataSourceRepository(this.apiRequestService)
  }

  @Cached()
  private getTaskIdRepository(): PutRepositoryMapper<BulkTaskIdEntity, string> {
    const dataSource = provideDefaultNetworkDataSource<BulkTaskIdEntity>(
      this.apiRequestService,
      BulkTaskIdEntity
    )

    const repository = new SinglePutDataSourceRepository(dataSource)

    return new PutRepositoryMapper(
      repository,
      new BulkTaskIdToStringMapper(),
      new VoidMapper()
    )
  }

  @Cached()
  private getCogsUpdateStatusRepository(): GetRepository<CogsUpdateStatus[]> {
    const dataSource = provideDefaultArrayNetworkDataSource(
      this.apiRequestService,
      CogsUpdateStatusEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ArrayMapper(
        new CogsUpdateStatusEntityToCogsUpdateStatusMapper()
      )
    )
  }

  @Cached()
  private getPaginatedProductsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ProductEntity>, PaginationOffsetLimit<Product>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ProductEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        this.productMapper
      )
    )
  }

  public provideGetAds(): GetAdsInteractor {
    return new GetAdsInteractor(
      new GetInteractor<PaginationOffsetLimit<Ad>>(this.getPaginatedAdsRepository())
    )
  }

  public provideGetAdsTotals(): GetAdsTotalsInteractor {
    return new GetAdsTotalsInteractor(
      new GetInteractor<AdsTotals>(this.getAdsTotalsRepository())
    )
  }

  public provideGetCogsManagement(): GetCogsManagementInteractor {
    return new GetCogsManagementInteractor(
      new GetInteractor<PaginationOffsetLimit<CogsManagement>>(this.getCogsManagementRepository())
    )
  }

  public provideExportCogsManagement(): ExportCogsManagementInteractor {
    return new ExportCogsManagementInteractor(
      new GetInteractor<string>(this.getStringRepository())
    )
  }

  public provideImportCogsManagement(): ImportCogsManagementInteractor {
    return new ImportCogsManagementInteractor(
      new PutInteractor<string>(this.getTaskIdRepository())
    )
  }

  public provideGetCogsSummary(): GetCogsSummaryInteractor {
    return new GetCogsSummaryInteractor(
      new GetInteractor<CogsSummary>(this.getCogsSummaryRepository())
    )
  }

  public provideGetListingReports(): GetListingReportsInteractor {
    return new GetListingReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<ListingReport>>(this.getListingReportsRepository())
    )
  }

  public provideGetOrders(): GetOrdersInteractor {
    return new GetOrdersInteractor(
      new GetInteractor<PaginationOffsetLimit<Order>>(this.getOrdersRepository())
    )
  }

  public provideGetProductReports(): GetProductReportsInteractor {
    return new GetProductReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<ProductReport>>(this.getProductReportsRepository())
    )
  }

  public provideGetSkuProductReports(): GetSkuProductReportsInteractor {
    return new GetSkuProductReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<SKUProductReport>>(this.getSKUProductReportsRepository())
    )
  }

  public provideGetProductCogs(): GetProductCogsInteractor {
    return new GetProductCogsInteractor(
      new GetInteractor<ProductCogs>(this.getProductCogsRepository())
    )
  }

  public provideReconcileCogs(): ReconcileCogsInteractor {
    return new ReconcileCogsInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideUpdateCogs(): UpdateCogsInteractor {
    return new UpdateCogsInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideCreateCogsPeriod(): CreateCogsPeriodInteractor {
    return new CreateCogsPeriodInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideUpdateProductCogsHistoricInteractor(): UpdateProductCogsHistoricInteractor {
    return new UpdateProductCogsHistoricInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideGetProductProducts(): GetProductProductsInteractor {
    return new GetProductProductsInteractor(
      new GetInteractor<PaginationOffsetLimit<Product>>(this.getPaginatedProductsRepository())
    )
  }

  public provideGetCogsUpdateStatus(): GetCogsUpdateStatusInteractor {
    return new GetCogsUpdateStatusInteractor(
      new GetInteractor<CogsUpdateStatus[]>(this.getCogsUpdateStatusRepository())
    )
  }

  public provideMarkCogsUpdateAsViewed(): MarkCogsUpdateAsViewedInteractor {
    return new MarkCogsUpdateAsViewedInteractor(
      new DeleteInteractor(this.getVoidRepository())
    )
  }
}
