import { Trans } from '@mbarzda/solid-i18next'
import { type Component, createEffect, createSignal, For, Show } from 'solid-js'
import MiniChevronDown from '../../assets/heroicons/MiniChevronDown'
import { PeriodType } from '../../features/shared/period-type'
import { clickOutsideCalendar } from '../../shared/directives/click-outside-calendar'
import CustomPeriodSelector from '../topbar/CustomPeriodSelector'
import Button, { ButtonStyle } from '../shared/components/Button'
import { useDateFormatter } from '../../shared/helpers/date-formatter'
import { type Periods, type PeriodTime } from '../../features/shared/period-state'
import { getPeriodsByDates, getPeriodsByType, theDayBefore } from '../../shared/helpers/date.functions'
import { mapTimestampToDate } from '../../features/shared/map-timestamp-to-date'
import { useUser } from '../../shared/providers/user-provider'
import { StarterPeriods } from '../../shared/providers/period.provider'

enum SelectState {
  Opening,
  Open,
  Closing,
  Closed
}

export const PeriodFilters = [
  PeriodType.Today,
  PeriodType.Yesterday,
  PeriodType.Last7Days,
  PeriodType.Last30Days,
  PeriodType.MonthToDate,
  PeriodType.LastMonth,
  PeriodType.QuarterToDate,
  PeriodType.LastQuarter,
  PeriodType.YearToDate,
  PeriodType.LastYear,
  PeriodType.AllTime
]

export interface PeriodFilterProps {
  selectedPeriod?: PeriodType
  setSelectedPeriod: (period: PeriodTime | undefined) => void
}

const PeriodFilter: Component<PeriodFilterProps> = (props) => {
  const { userPurchases } = useUser()
  const [selectState, setSelectState] = createSignal(SelectState.Closed)
  const [isCustomPeriodSelected, setIsCustomPeriodSelected] = createSignal(false)
  const [selectedPeriod, setSelectedPeriod] = createSignal<PeriodType>(
    props.selectedPeriod ? props.selectedPeriod : (userPurchases()?.isPro ? PeriodType.AllTime : PeriodType.MonthToDate)
  )
  const [selectedCustomPeriod, setSelectedCustomPeriod] = createSignal<Periods | undefined>(undefined)
  const { shortDateFormatter } = useDateFormatter()

  createEffect(() => {
    if (props.selectedPeriod) {
      setSelectedPeriod(props.selectedPeriod)
    }
  })

  const openSelector = (): void => {
    if (selectState() !== SelectState.Open && selectState() !== SelectState.Opening) {
      setSelectState(SelectState.Opening)
      setTimeout(() => {
        setSelectState(SelectState.Open)
      }, 10)
    }
  }

  const closeSelector = (): void => {
    setIsCustomPeriodSelected(false)
    if (selectState() !== SelectState.Closed && selectState() !== SelectState.Closing) {
      setSelectState(SelectState.Closing)
      setTimeout(() => {
        setSelectState(SelectState.Closed)
      }, 300)
    }
  }

  const onActionOptionSelected = (period: PeriodType): void => {
    setSelectedPeriod(period)
    if (period === PeriodType.AllTime) {
      props.setSelectedPeriod(undefined)
    } else {
      props.setSelectedPeriod(getPeriodsByType(period).current)
    }
    closeSelector()
  }

  const toggleMenu = (): void => {
    if (selectState() === SelectState.Open) {
      closeSelector()
    }
    if (selectState() === SelectState.Closed) {
      openSelector()
    }
  }

  const setCustomPeriod = (): void => {
    if (!userPurchases()?.isPro) return
    setIsCustomPeriodSelected(true)
  }

  const onCustomPeriodSelected = (from: Date, to: Date): void => {
    const { current: period } = getPeriodsByDates(from, to)
    setSelectedCustomPeriod()
    props.setSelectedPeriod(period)
    setSelectedPeriod(PeriodType.Custom)
    closeSelector()
  }

  const isAvailablePeriod = (period: PeriodType): boolean => StarterPeriods.includes(period) || !!userPurchases()?.isPro

  return (
    <div class="flex w-full relative" ref={el => { clickOutsideCalendar(el, closeSelector) } }>
      <Button action={toggleMenu} style={ButtonStyle.White} width="full">
        <div class="flex justify-between items-center w-full font-normal">
          <Show when={selectedPeriod() === 'custom'} fallback={
            <Trans key={`ls_period_${selectedPeriod()}`} />
          }>
            <div>
              <span class="w-fit">{shortDateFormatter(mapTimestampToDate(selectedCustomPeriod()?.current.from)!)}</span>
              <Show when={selectedCustomPeriod()?.current.to}>{(toDate) => (
                <span> - {shortDateFormatter(theDayBefore(mapTimestampToDate(toDate())!))}</span>
              )}</Show>
            </div>
          </Show>
          <MiniChevronDown />
        </div>
      </Button>

      <div class={`${selectState() === SelectState.Closed ? 'hidden' : 'visible'} ${selectState() === SelectState.Open ? 'opacity-100' : 'opacity-0'}` + ' absolute left-0 top-10 min-w-full z-10 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'} tabindex="-1" role="listbox" aria-labelledby="listbox-label">
        <Show when={!isCustomPeriodSelected()} fallback={
          <CustomPeriodSelector close={() => setIsCustomPeriodSelected(false)} submit={ onCustomPeriodSelected } />
        }>
          <ul role="listbox" aria-labelledby="listbox-label" class="min-w-full text-sm">
            <For each={PeriodFilters}>{(period, index) => (
              <>
                <li onClick={() => {
                  if (isAvailablePeriod(period)) {
                    onActionOptionSelected(period)
                  }
                }}
                    class={`${isAvailablePeriod(period) ? 'text-gray-900' : 'text-gray-500'} bg-white hover:bg-gray-100 transition duration-300  h-9 select-none py-2 pl-3 pr-9 cursor-pointer`}>
                  <span class="block truncate" classList={{ 'font-bold': selectedPeriod() === period }}>
                    <Trans key={`ls_period_${period}`}/>
                  </span>
                </li>
                <Show when={index() % 2 === 1 || index() === PeriodFilters.length - 1}>
                  <div class="w-full h-px bg-gray-200 my-1"/>
                </Show>
              </>
            )}</For>
            <li onClick={setCustomPeriod}
                class={`bg-white hover:bg-gray-100 transition duration-300 ${userPurchases()?.isPro ? 'text-gray-900' : 'text-gray-500'} h-9 relative select-none py-2 pl-3 pr-9 cursor-pointer`}>
              <span class="block truncate" classList={{ 'font-bold': selectedPeriod() === 'custom' }}>
                <Trans key={'ls_period_custom'}/>
              </span>
            </li>
          </ul>
        </Show>
      </div>
    </div>
  )
}

export default PeriodFilter
