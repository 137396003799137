import { Trans } from '@mbarzda/solid-i18next'
import { CheckoutEventNames } from '@paddle/paddle-js'
import { A } from '@solidjs/router'
import { t } from 'i18next'
import { type Component, createSignal, onMount, Show } from 'solid-js'
import MiniCube from '../../assets/heroicons/MiniCube'
import MiniInformationCircle from '../../assets/heroicons/MiniInformationCircle'
import { MainModule } from '../../features/main-module'
import { type Credit } from '../../features/payment/domain/models/credit'
import { OfferingProductInterval } from '../../features/payment/domain/models/offering'
import { isSubscription, type Subscription, SubscriptionStatus } from '../../features/payment/domain/models/subscription'
import { useUser } from '../../shared/providers/user-provider'
import { AppRoutes } from '../../shared/app-routes'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'
import { daysDiff, formatDateToRegular } from '../../shared/helpers/date.functions'
import { usePaddle } from '../../shared/providers/paddle.provider'
import { useTracking } from '../../shared/providers/tracking.provider'
import StatusCell from '../shared/components/StatusCell'
import SectionCard from './SectionCard'
import YourPaymentsSection from './YourPaymentsSection'

export const SubscriptionStatusColor: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.Active]: 'green',
  [SubscriptionStatus.Canceled]: 'red',
  [SubscriptionStatus.Inactive]: 'red',
  [SubscriptionStatus.PastDue]: 'red',
  [SubscriptionStatus.Paused]: 'red',
  [SubscriptionStatus.Trialing]: 'blue'
}

export interface SubscribedSectionCardProps {
  readonly onUpdateSubscription: (suId: string) => void
}

const paymentComponent = MainModule.getPaymentComponent()
const getAvailableCredit = paymentComponent.provideGetAvailableCredit()
const getPurchases = paymentComponent.provideGetPurchases()
const cancelSubscription = paymentComponent.provideCancelSubscription()
const reactivateSubscription = paymentComponent.provideReactivateSubscription()

const SubscribedSectionCard: Component<SubscribedSectionCardProps> = (props) => {
  const { currentUser, userPurchases, setUserPurchases, currentSubscription } = useUser()
  const { paddle } = usePaddle()
  const { trackEvent, trackUser } = useTracking()
  const { currencyFormatter } = useNumberFormatter()

  const [isLoading, setIsLoading] = createSignal(true)
  const [availableCredit, setAvailableCredit] = createSignal<Credit>()

  const currencyFormatterFn = (value: number, currency: string): string => new Intl
    .NumberFormat(window.navigator.language ?? 'en-US', { style: 'currency', currency, maximumFractionDigits: 2, minimumFractionDigits: 2 })
    .format(value)

  onMount(async () => {
    void getAvailableCredit.execute().then((creditList) => setAvailableCredit(creditList[0]))
    updatePaddleCallback()
    setIsLoading(false)
  })

  const updatePaddleCallback = (): void => {
    paddle()?.Update({
      eventCallback: async (event) => {
        if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
          setTimeout(async () => {
            trackEvent(
              'Subscription Checkout',
              { completed: true }
            )
            paddle()?.Checkout.close()
            const productId = event.data?.items[0].price_id
            if (productId) {
              props.onUpdateSubscription(productId)
            }
          }, 1000)
        }
      }
    })
  }

  const refreshUser = async (): Promise<void> => {
    const purchases = await getPurchases.execute()
    setUserPurchases(purchases)
    trackUser(currentUser()!, purchases)
  }

  const onReactivateSubscription = async (subscription: Subscription): Promise<void> => {
    setIsLoading(true)
    trackEvent('Subscription Reactivate', { offering: subscription.productPrice.name })
    await reactivateSubscription.execute(subscription.id)
    await refreshUser()
    setIsLoading(false)
  }

  const onActionCancelSubscription = async (subscription: Subscription): Promise<void> => {
    const result = await window.profitwell('init_cancellation_flow', { subscription_id: subscription.id })

    if (result.status !== 'retained' && result.status !== 'aborted') {
      setIsLoading(true)
      await cancelSubscription.execute(subscription.id)
      await refreshUser()
      setIsLoading(false)
    }
  }

  return (
    <>
      <SectionCard icon={<MiniCube/>} title='ls_generic_plan' isLoading={isLoading()} styleNumColumns='half'>
        <Show when={isSubscription(currentSubscription())}>{(sub) => (
          <div class="flex flex-col gap-6">
            <div class="flex flex-col gap-6">
              <div class="flex justify-between ">
                <div class="flex flex-col gap-4 sm:gap-2">
                  <div class="font-semibold flex flex-col">
                    <span>{sub().productPrice.name}</span>
                    <div class="sm:hidden">
                      <StatusCell status={`ls_subscription_status_${sub().status}`}
                                  color={SubscriptionStatusColor[sub().status]}/>
                    </div>
                  </div>
                  <span class="text-sm">
                    <Show
                      when={sub().productPrice.billingInterval?.interval === OfferingProductInterval.Month}>
                      <Trans key="ls_generic_monthly"/> - {currencyFormatter(sub().productPrice.price)}/<Trans
                      key="ls_shared_month_short"/>
                    </Show>
                    <Show when={sub().productPrice.billingInterval?.interval === OfferingProductInterval.Year}>
                      <div class="flex gap-1">
                        <span><Trans key="ls_generic_annual"/> - {currencyFormatter(sub().productPrice.price, 2, 'USD')}</span>
                        <span class="text-gray-500">
                          ({currencyFormatter(sub().productPrice.price / 12, 2, 'USD')}/<Trans key="ls_shared_month_short"/>)
                        </span>
                      </div>
                    </Show>
                    <Show when={sub().status === SubscriptionStatus.Trialing && sub().trialEndsDate}>
                      <span class="text-sm text-gray-500 sm:hidden">
                        <Trans key="ls_subscription_trial_remaining"
                               options={{ days: daysDiff(new Date(), sub().trialEndsDate!) }}/>
                      </span>
                    </Show>
                  </span>
                </div>
                <div class="h-6 flex-col items-end gap-2 hidden sm:flex">
                  <StatusCell status={`ls_subscription_status_${sub().status}`}
                              color={SubscriptionStatusColor[sub().status]}/>
                  <Show when={sub().status === SubscriptionStatus.Trialing}>
                    <span class="text-sm text-gray-500">
                      <Trans key="ls_subscription_trial_remaining"
                             options={{ days: daysDiff(new Date(), sub().trialEndsDate!) }}/>
                    </span>
                  </Show>
                </div>
              </div>
              <Show when={!sub().isSubscriptionActive}>
                <a href={sub().manageUrl} class="btn btn--white" target="_blank">
                  <Trans key="ls_subscription_manage"/>
                </a>
              </Show>
              <Show when={sub().isSubscriptionActive}>
                <Show when={sub().status !== SubscriptionStatus.Canceled}>
                  <Show when={sub().nextPaymentDate}>{(paymentDate) => (
                    <div class="flex gap-2 text-sm">
                      <span class="font-semibold"><Trans key="ls_subscription_next_payment"/></span>
                      <span>{ formatDateToRegular(paymentDate()) }</span>
                    </div>
                  )}</Show>
                  <div class="flex justify-between flex-col sm:flex-row gap-4">
                    <div class="flex gap-2 sm:gap-4 flex-col sm:flex-row">
                      <Show when={!!userPurchases()?.isPro || !!userPurchases()?.isStarter}>
                        <A href={AppRoutes.Subscription()} class="btn btn--secondary">
                          <Trans key="ls_subscription_change_plan"/>
                        </A>
                      </Show>
                      <a href={sub().manageUrl} class="btn btn--white" target="_blank"><Trans key="ls_subscription_manage"/></a>
                    </div>
                    <button onClick={async () => {
                      await onActionCancelSubscription(sub())
                    }} class="btn !text-red-500 !shadow-none !font-semibold">
                      <Trans key="ls_subscription_cancel"/>
                    </button>
                  </div>
                </Show>
                <Show when={sub().status === SubscriptionStatus.Canceled && sub().endBillingPeriodDate}>{(expirationDate) => (
                  <div class="flex flex-col gap-6">
                    <div class="flex gap-2">
                      <span class="font-semibold text-red-500"><Trans key="ls_subscription_expires_on"/></span>
                      <span>{formatDateToRegular(expirationDate())}</span>
                    </div>
                    <div class="flex gap-4">
                      <Show when={userPurchases()?.isPro}>
                        <A href={AppRoutes.Subscription()} class="btn btn--secondary"><Trans key="ls_subscription_change_plan"/></A>
                      </Show>
                      <button onClick={async () => { await onReactivateSubscription(sub()) }} class="text-sm text-white w-fit font-semibold h-9 flex items-center px-3 bg-japanese-600 rounded-md">
                        <Trans key="ls_subscription_reactivate"/>
                      </button>
                    </div>
                  </div>
                )}</Show>
              </Show>
            </div>
            <Show when={availableCredit()?.amount}>{(available) => (
              <>
                <div class="h-px w-full bg-gray-200"/>
                <div class="flex flex-col gap-4">
                  <div class="flex justify-between">
                    <span class="text-sm font-bold">{t('ls_subscription_your_balance')}</span>
                    <span class="text-sm">{currencyFormatterFn(available(), availableCredit()!.currency)}</span>
                  </div>
                  <div class="flex gap-2 text-gray-500">
                    <span class="w-4"><MiniInformationCircle size={4}/></span>
                    <span class="text-xs" innerHTML={t('ls_subscription_balance_info')}/>
                  </div>
                </div>
              </>
            )}</Show>
            <YourPaymentsSection />
          </div>
        )}</Show>
      </SectionCard>
    </>
  )
}

export default SubscribedSectionCard
