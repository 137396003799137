import { type Component, Show } from 'solid-js'
import PeriodSelector from './PeriodSelector'
import { useLocation } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'
import { Trans } from '@mbarzda/solid-i18next'
import { useShop } from '../../shared/providers/shop.provider'
import { type Shop } from '../../features/shop/domain/models/shop'
import { formatDateToTimeAMPM } from '../../shared/helpers/date.functions'
import { useDateFormatter } from '../../shared/helpers/date-formatter'
import UserMenu from './UserMenu'

interface TopbarProps {
  onSidebarOpen?: () => void
  hidden?: boolean
}

const Topbar: Component<TopbarProps> = (props) => {
  const { selectedShop } = useShop()
  const { shortDateFormatter } = useDateFormatter()
  const location = useLocation()

  const isReportsDate = (): boolean => {
    return location.pathname.includes(AppRoutes.Dashboard()) ||
      location.pathname.includes(AppRoutes.Listings()) ||
      location.pathname.includes(AppRoutes.Products()) ||
      location.pathname.includes(AppRoutes.Orders())
  }

  const isListingsDate = (): boolean => {
    return location.pathname.includes(AppRoutes.CogsManagement())
  }

  const getLastUpdateByUrl = (shop: Shop): Date | undefined => {
    return isListingsDate()
      ? shop.lastListingFetchDate
      : isReportsDate()
        ? shop.lastReportFetchDate
        : undefined
  }

  return (
    <div class="sticky top-0 z-40 flex h-20 shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">

      <Show when={!!props.onSidebarOpen}>
        <button onClick={props.onSidebarOpen} type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden">
          <span class="sr-only">Open sidebar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
          </svg>
        </button>

        <div class="h-6 w-px bg-gray-900/10 hidden sm:block lg:hidden" aria-hidden="true" />
      </Show>

      <div class="flex flex-1 gap-x-1 sm:gap-x-6 self-stretch lg:gap-x-10">
        <div class="relative flex flex-1 self-center">
          <Show when={!props.hidden}>
            <PeriodSelector />
          </Show>
        </div>
        <Show when={selectedShop()}>{(shop) => (
          <Show when={getLastUpdateByUrl(shop())}>{(date) => (
            <div class="hidden sm:flex w-fit flex-col gap-1 justify-center text-xs">
              <span class="text-gray-500"><Trans key="ls_topbar_last_updated"/></span>
              <span class="text-xs">{shortDateFormatter(date())} {formatDateToTimeAMPM(date())}</span>
            </div>
          )}</Show>
        )}</Show>
        <UserMenu />
      </div>
    </div>
  )
}

export default Topbar
