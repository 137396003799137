import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { Trans } from '@mbarzda/solid-i18next'
import { useLocation } from '@solidjs/router'
import { type Component, onMount, createEffect, createSignal, For, Show } from 'solid-js'
import CloudArrowUp from '../../assets/heroicons/CloudArrowUp'
import CubeSolid from '../../assets/heroicons/CubeSolid'
import { MainModule } from '../../features/main-module'
import { useError } from '../../shared/providers/error.provider'
import { useShop } from '../../shared/providers/shop.provider'
import CogsBulkUploadDialog from '../cogs/CogsBulkUploadDialog'
import ProcessingCogsBanner from '../cogs/ProcessingCogsBanner'
import SearchBar from '../shared/components/SearchBar'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import TablePagination from '../shared/components/TablePagination'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import CogsManagementTableRow from './CogsManagementTableRow'
import { type CogsManagement as Cogs } from '../../features/product/domain/models/cogs-management'
import Button, { ButtonStyle } from '../shared/components/Button'
import MiniArrowDownTray from '../../assets/heroicons/MiniArrowDownTray'
import PencilOutline from '../../assets/heroicons/PencilOutline'
import UpdateCogsDialog from './UpdateCogsDialog'
import { t } from 'i18next'
import CogsManagementTableFilters, { type CogsManagementFilters } from './CogsManagementFilters'
import { downloadCSV } from '../../shared/helpers/download-csv'
import MainMissingCogsBanner from '../dashboard/MainMissingCogsBanner'
import { type CogsSummary } from '../../features/product/domain/models/cogs-summary'
import { StarterPeriods } from '../../shared/providers/period.provider'
import { useUser } from '../../shared/providers/user-provider'
import { type PeriodType } from '../../features/shared/period-type'
import { getPeriodsByType } from '../../shared/helpers/date.functions'

const CogsManagement: Component = () => {
  const DEFAULT_PAGE_SIZE = 15
  const [filters, setFilters] = createSignal<CogsManagementFilters>({})
  const [orderBy, setOrderBy] = createSignal<string | undefined>(undefined)
  const [searchTerm, setSearchTerm] = createSignal<string>('')
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [isUploadingCogs, setIsUploadingCogs] = createSignal<boolean>(false)
  const [selectedProducts, setSelectedProducts] = createSignal<Cogs[]>([])
  const [cogsBeingUpdated, setCogsBeingUpdated] = createSignal<Cogs[]>([])
  const [cogsSummary, setCogsSummary] = createSignal<CogsSummary | undefined>(undefined)
  const [cogs, setCogs] = createSignal<PaginationOffsetLimit<Cogs>>()
  const [page, setPage] = createSignal(0)
  const [queryPeriod, setQueryPeriod] = createSignal<PeriodType | undefined>()

  let selectAllRef!: HTMLInputElement

  const location = useLocation()

  const { selectedShopId } = useShop()
  const { userPurchases } = useUser()
  const { setError } = useError()

  const columns: TableColumn[] = [
    { id: 'select' },
    { id: 'image' },
    { id: 'product.sku', sortable: true },
    { id: 'product.listing.title', withHint: true, sortable: true },
    { id: 'orders_count', withHint: true, sortable: true },
    { id: 'last_attributed_cogs', withHint: true, sortable: true },
    { id: 'cogs_attribution.missing', withHint: true, sortable: true }
  ]

  const getCogsManagement = MainModule.getProductComponent().provideGetCogsManagement()
  const exportCogsManagement = MainModule.getProductComponent().provideExportCogsManagement()
  const getCogsSummary = MainModule.getProductComponent().provideGetCogsSummary()

  onMount(() => {
    const params = new URLSearchParams(location.search)
    const { period } = Object.fromEntries(params)
    if (period) {
      if (StarterPeriods.includes(period as PeriodType) || userPurchases()?.isPro) {
        setQueryPeriod(period as PeriodType)
        const periods = getPeriodsByType(period as PeriodType)
        setFilters((filters) => ({
          ...filters,
          period: periods.current,
          isCogsIssuesActive: true
        }))
      }
    }
  })

  const getTableData = (page: number = 1): void => {
    const shopId = selectedShopId()
    if (!shopId) {
      return
    }

    setIsLoading(true)

    const offset = (page - 1) * DEFAULT_PAGE_SIZE

    void getCogsSummary.execute(shopId, filters().period?.from, filters().period?.to).then((summary) => {
      setCogsSummary(summary)
    })

    executeWithUrlScope(
      async () => await getCogsManagement.execute(
        shopId,
        offset,
        DEFAULT_PAGE_SIZE,
        filters(),
        orderBy(),
        searchTerm()
      ), location
    )
      .then((cogs) => {
        setCogs(cogs)
        updateSelectAllCheckbox()
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateSelectAllCheckbox = (): void => {
    const cogsList = cogs()?.values
    if (!selectAllRef || !cogsList) return

    selectAllRef.checked = cogsList.every(cogs => selectedProducts().includes(cogs))
  }

  const showNoResults = (): boolean => {
    return cogs()?.values.length === 0
  }

  const isFiltering = (): boolean => {
    if (searchTerm()) return true
    return !!filters().isCogsIssuesActive || !!filters().isPersonalizedActive || !!filters().productType?.length || !!filters().cogsAttribution?.length
  }

  createEffect(() => {
    orderBy()
    filters()
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    getTableData(page())
  })

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), showNoResults(), isFiltering(), cogs()?.values)
  }

  let cogsStatusPullingAction = (): void => undefined
  const cogsUpdatedAction = (action: () => void): void => {
    cogsStatusPullingAction = action
  }

  const cogsUploaded = (): void => {
    setIsUploadingCogs(false)
    cogsStatusPullingAction()
    setPage(1)
  }

  const onSwitchSelectAll = (value: boolean): void => {
    setSelectedProducts((products) => {
      const cogsList = cogs()?.values
      if (!cogsList) return products

      const newProduct = new Set(cogsList)

      return value
        ? [...new Set([...products, ...newProduct])]
        : products.filter(product => !newProduct.has(product))
    })
  }

  const onExportCogs = async (): Promise<void> => {
    const shopId = selectedShopId()
    if (!shopId) {
      return
    }

    const file = await exportCogsManagement.execute(
      shopId,
      filters(),
      orderBy(),
      searchTerm())

    downloadCSV(file, 'cogs-management.csv')
  }

  const onSelectionChange = (product: Cogs, isSelected: boolean): void => {
    setSelectedProducts((products) =>
      isSelected
        ? [...new Set([...products, product])]
        : products.filter(p => p !== product)
    )
    updateSelectAllCheckbox()
  }

  const isProductSelected = (cogs: Cogs): boolean => {
    return selectedProducts().includes(cogs)
  }

  const cogsUpdated = async (): Promise<void> => {
    setCogsBeingUpdated([])
    setSelectedProducts([])
    getTableData(page())
  }

  const onUnselectAll = (): void => {
    setSelectedProducts([])
    updateSelectAllCheckbox()
  }

  const editSingleCogs = (cogs: Cogs[]): void => {
    setCogsBeingUpdated(cogs)
  }

  const onFixNow = (): void => {
    setSearchTerm('')
    setFilters((filters) => ({
      ...filters,
      isCogsIssuesActive: true,
      isPersonalizedActive: false,
      cogsAttribution: undefined,
      productType: undefined
    }))
  }

  return (
    <>
      <Show when={cogsBeingUpdated().length > 0}>
        <UpdateCogsDialog cogsList={cogsBeingUpdated()} cancel={() => setCogsBeingUpdated([])} cogsUpdated={cogsUpdated} />
      </Show>
      <Show when={isUploadingCogs()}>
        <CogsBulkUploadDialog cancel={() => setIsUploadingCogs(false)} uploaded={cogsUploaded}/>
      </Show>
      <div class="flex flex-col gap-4">
        <ProcessingCogsBanner refresh={cogsUpdatedAction} onFinishPulling={() => { getTableData(page()) }}/>
        <Show when={cogsSummary()}>{(summary) => (
          <MainMissingCogsBanner summary={summary()} action={onFixNow}/>
        )}</Show>
        <div class="flex justify-between">
          <h1 class="page-title">
            <CubeSolid/><Trans key="ls_cogs_management_title"/>
          </h1>
        </div>
        <div class="flex flex-col sm:flex-row gap-4 items-center w-full">
          <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_cogs_management" searchTerm={searchTerm()} />
          <div class="flex gap-4 w-full sm:w-fit">
            <Button style={ButtonStyle.White} action={onExportCogs}>
              <MiniArrowDownTray size={4}/> <Trans key={'ls_generic_export'}/>
            </Button>
            <Button style={ButtonStyle.White} action={() => setIsUploadingCogs(true)}>
              <CloudArrowUp size={4}/> <Trans key={'ls_cogs_import'}/>
            </Button>
          </div>
        </div>
        <CogsManagementTableFilters setFilters={setFilters} filters={filters()} forcedPeriod={queryPeriod()}/>
        <Show when={selectedProducts().length > 1}>
          <div class="flex items-center justify-between bg-blue_gray-200 p-2 pl-4 rounded-lg text-sm">
            <span innerHTML={t('ls_table_num_selected', { num: selectedProducts().length })} />
            <div class="flex items-center gap-4">
              <button class="text-blue_gray-500 hover:text-blue_gray-700" onClick={onUnselectAll}>
                <Trans key="ls_table_clear_selection" />
              </button>
              <Button style={ButtonStyle.Primary} action={() => setCogsBeingUpdated(selectedProducts())}>
                <PencilOutline size={4}/><Trans key="ls_cogs_edit" />
              </Button>
            </div>
          </div>
        </Show>
        <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
          <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
              <Table columns={columns} status={getTableStatus} setOrderBy={setOrderBy} orderBy={orderBy}
                     tableId="product_costs" onSelectAll={onSwitchSelectAll} setSelectorRef={(ref) => (selectAllRef = ref)}
              >
                <For each={cogs()?.values}>{(cogs) => (
                  <CogsManagementTableRow
                    cogsManagement={cogs}
                    isSelected={isProductSelected(cogs)}
                    onSelectionChange={
                      (isSelected: boolean) => { onSelectionChange(cogs, isSelected) }
                    }
                    onEditSingleCogs={() => { editSingleCogs([cogs]) }}
                    isEditable={selectedProducts().length < 2}
                    isMultipleSelected={selectedProducts().length > 1}
                  />
                )}</For>
              </Table>
            </div>
            <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
              <TablePagination totalCount={cogs()?.size ?? 0} page={page} setPage={setPage}/>
            </Show>
          </div>
        </div>
      </div>
    </>
  )
}

export default CogsManagement
