import { PutNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { HttpParameters } from '@coris-ts/data/parameter-type'

export class UpdateCurrentUserQuery extends PutNetworkQuery {
  constructor (
    private readonly params: { name?: string, utms?: Record<string, string> }
  ) {
    super(ApiEndpoint.Me)
  }

  public get body(): HttpParameters {
    return {
      ...(this.params.name ? { name: this.params.name } : {}),
      ...(this.params.utms ? { utms: this.params.utms } : {})
    }
  }
}
