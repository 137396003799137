import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type TimeseriesResolution } from '../../../../components/shared/analytics.vm'
import { correctDateToCurrentOffset } from '../../../../shared/helpers/date.functions'
import { type PeriodTime } from '../../../shared/period-state'
import {
  GetListingAnalyticsTimeseriesQuery
} from '../../data/queries/get-listing-analytics-timeseries.query'
import { type Timeseries } from '../models/timeseries'

export class GetListingAnalyticsTimeseriesInteractor {
  constructor(
    private readonly getAnalytics: GetInteractor<Timeseries>
  ) {
  }

  public async execute (shopId: number, period: PeriodTime, resolution: TimeseriesResolution, listingId?: number): Promise<Timeseries> {
    if (!listingId) {
      throw new Error('listingId is required')
    }
    const startOfPeriod = correctDateToCurrentOffset(period.from * 1000) / 1000
    const endOfPeriod = correctDateToCurrentOffset(period.to * 1000) / 1000
    return await this.getAnalytics.execute(new GetListingAnalyticsTimeseriesQuery(shopId, listingId, startOfPeriod, endOfPeriod, resolution))
  }
}
