import { PostNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpBody, type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class UpdateCogsQuery extends PostNetworkQuery {
  constructor (
    private readonly cogsValue: number,
    private readonly productIds: number[],
    private readonly shopId: number
  ) {
    super(ApiEndpoint.CogsManagementUpdate)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      cogs: this.cogsValue
    }
  }

  public get body(): HttpBody {
    return {
      product_ids: this.productIds
    }
  }
}
