import { type Component, createSignal } from 'solid-js'
import { clickOutside } from '../../shared/directives/click-outside'
import { useAuth } from '../../shared/providers/auth-provider'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'
import { Trans } from '@mbarzda/solid-i18next'
import MiniUserCircle from '../../assets/heroicons/MiniUserCircle'
import MiniArrowRightStartOnRectangle from '../../assets/heroicons/MiniArrowRightStartOnRectangle'
import QuestionMarkCircleSolid from '../../assets/heroicons/QuestionMarkCircleSolid'
import Cog8ToothSolid from '../../assets/heroicons/Cog8ToothSolid'
import { useUser } from '../../shared/providers/user-provider'
import AcademicCapSolid from '../../assets/heroicons/AcademicCapSolid'
import { useTracking } from '../../shared/providers/tracking.provider'

const UserMenu: Component = () => {
  const [isMenuOpen, setMenuOpen] = createSignal(false)
  const { auth } = useAuth()
  const { currentUser, hasAccess } = useUser()
  const trackingService = useTracking()

  const toggleMenu = (): void => {
    setMenuOpen(!isMenuOpen())
  }

  const onLogout = async (): Promise<void> => {
    trackingService.trackLogout()
    localStorage.clear()

    await auth.logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })
  }

  return (
    <div class="flex items-center relative">
      <button onClick={toggleMenu} type="button" class="-m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
        <span class="sr-only">Open user menu</span>
        <img class="h-8 min-w-[32px] w-8 rounded-full bg-gray-50" referrerpolicy="no-referrer" src={currentUser()?.pictureUrl} alt=""/>
        <span class="hidden lg:flex lg:items-center">
          <svg class="ml-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
      <div ref={el => {
        clickOutside(el, () => setMenuOpen(false))
      }}
           class={`${isMenuOpen() ? 'visible' : 'hidden'} flex flex-col absolute top-14 right-0 z-10 mt-2.5 w-fit origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}
           role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button"
           tabindex="-1"
      >
        <A href={hasAccess() ? AppRoutes.AccountSettings() : AppRoutes.UnsubscribedAccountSettings()}
           onClick={toggleMenu}
           class="flex gap-2 whitespace-nowrap bg-white hover:bg-gray-100 transition-200 h-9 pl-3 pr-6 text-sm text-gray-900 items-center"
           role="menuitem" tabIndex="-1">
          <MiniUserCircle /><Trans key="ls_user_menu_account" />
        </A>
        <A href={AppRoutes.Settings()}
           onClick={toggleMenu}
           class="flex gap-2 whitespace-nowrap bg-white hover:bg-gray-100 transition-200 h-9 pl-3 pr-6 text-sm text-gray-900 items-center"
           role="menuitem" tabIndex="-1">
          <Cog8ToothSolid size={5} /><Trans key="ls_user_menu_settings" />
        </A>
        <A href={hasAccess() ? AppRoutes.Education() : AppRoutes.NoSubscriptionEducation()}
           onClick={toggleMenu}
           class="flex gap-2 whitespace-nowrap bg-white hover:bg-gray-100 transition-200 h-9 pl-3 pr-6 text-sm text-gray-900 items-center"
           role="menuitem" tabIndex="-1">
          <AcademicCapSolid size={5} /><Trans key="ls_user_menu_education" />
        </A>
        <a href="https://support.profittree.io/"
           target="_blank"
           rel="noreferrer"
           onClick={toggleMenu}
           class="flex gap-2 whitespace-nowrap bg-white hover:bg-gray-100 transition-200 h-9 pl-3 pr-6 text-sm text-gray-900 items-center"
           role="menuitem" tabIndex="-1">
          <QuestionMarkCircleSolid size={5} /><Trans key="ls_user_menu_support" />
        </a>
        <button onClick={onLogout}
                class="flex gap-2 bg-white hover:bg-gray-100 transition-200 h-9 pl-3 pr-6 text-sm text-gray-900 items-center"
                role="menuitem" tabIndex="-1">
          <MiniArrowRightStartOnRectangle /><Trans key="ls_user_menu_logout" />
        </button>
      </div>
    </div>
  )
}

export default UserMenu
