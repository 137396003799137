import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetCogsManagementQuery } from '../../data/queries/get-cogs-management.query'
import { type CogsManagement } from '../models/cogs-management'
import { type CogsManagementFilters } from '../../../../components/cogs-management/CogsManagementFilters'
import type { SortDirection } from '../../../../shared/models/sort-direction'

export class GetCogsManagementInteractor {
  constructor(
    private readonly getCogsManagement: GetInteractor<PaginationOffsetLimit<CogsManagement>>
  ) {
  }

  public async execute (
    shopId: number,
    offset: number,
    limit: number,
    filters: CogsManagementFilters,
    orderBy?: string,
    searchTerm?: string
  ): Promise<PaginationOffsetLimit<CogsManagement>> {
    const sortBy = orderBy?.split(':')[0]
    const sortDirection = orderBy?.split(':')[1] as SortDirection

    return await this.getCogsManagement.execute(new GetCogsManagementQuery(shopId, offset, limit, filters, sortBy, sortDirection, searchTerm))
  }
}
