import {
  type DeleteDataSource,
  type GetDataSource,
  type PutDataSource
} from './data-source/data-source'
import { type Operation } from './operation/operation'
import { type Query } from './query/query'
import { type Repository } from './repository'
import type {ApiRequestService} from "./data-source/api-request.service";
import { provideDefaultNetworkDataSource } from "./data-source/network.data-source";

export class SingleStringDataSourceRepository implements Repository<string> {
  constructor (
    private readonly getDataSource: GetDataSource<string>,
    private readonly putDataSource: PutDataSource<string>,
    private readonly deleteDataSource: DeleteDataSource,
  ) {
  }

  public async get(query: Query, _operation: Operation): Promise<string> {
    return await this.getDataSource.get(query)
  }

  public async put(value: string | undefined, query: Query, _operation: Operation): Promise<string> {
    return await this.putDataSource.put(value, query)
  }

  public async delete(query: Query, _operation: Operation): Promise<void> {
    await this.deleteDataSource.delete(query)
  }
}

export function provideStringNetworkDataSourceRepository(requestService: ApiRequestService): Repository<string> {
  const dataSource = provideDefaultNetworkDataSource<string>(requestService)
  return new SingleStringDataSourceRepository(dataSource, dataSource, dataSource)
}

