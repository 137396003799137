import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { CogsManagementFilters } from '../../../../components/cogs-management/CogsManagementFilters'
import type { SortDirection } from '../../../../shared/models/sort-direction'

export class ExportCogsManagementQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly filters: CogsManagementFilters,
    private readonly sortBy?: string,
    private readonly sortDirection?: SortDirection,
    private readonly searchTerm?: string
  ) {
    super(ApiEndpoint.CogsManagementBulk)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    const productFilters = [
      this.filters.isPersonalizedActive ? 'personalized' : null,
      this.filters.isCogsIssuesActive ? 'cogs_issues' : null
    ].filter(Boolean)

    return {
      ...this.searchTerm && { search: this.searchTerm },
      ...this.filters.productType?.length && { product_types: this.filters.productType },
      ...this.filters.cogsAttribution?.length && { cogs_sources: this.filters.cogsAttribution },
      ...this.filters.period && { from_date: this.filters.period.from },
      ...this.filters.period && { to_date: this.filters.period.to },
      ...this.sortBy && { sort_by: this.sortBy },
      ...this.sortDirection && { sort_order: this.sortDirection },
      ...productFilters.length && { product_filters: productFilters }
    }
  }
}
