import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { type ShopType } from '../../../features/shop/domain/models/shop'
import { MainModule } from '../../../features/main-module'
import { useShop } from '../../../shared/providers/shop.provider'
import { useTracking } from '../../../shared/providers/tracking.provider'
import MiniExclamationCircle from '../../../assets/heroicons/MiniExclamationCircle'
import MiniArrowsRightLeft from '../../../assets/heroicons/MiniArrowsRightLeft'
import { capitalize } from '../../../shared/helpers/string.functions'

export interface ReconnectionNeededBannerProps {
  readonly shopType: ShopType
}

const ReconnectionNeededBanner: Component<ReconnectionNeededBannerProps> = (props) => {
  const { selectedShopId } = useShop()
  const { trackEvent } = useTracking()

  const reconnect = (): void => {
    const startShopConnection = MainModule.getShopComponent().provideStartShopConnection()
    void startShopConnection.execute(props.shopType, selectedShopId(), trackEvent)
  }

  return (
    <div class="relative flex gap-2 items-center bg-red-100 justify-between min-h-[68px] flex-col sm:flex-row mb-4 px-8 xl:px-4 py-4 xl:py-0 rounded-lg">
      <div class="flex flex-col xl:flex-row xl:items-center text-sm text-gray-900 flex-grow">
        <div class="font-semibold flex gap-2">
          <span class="text-red-500"><MiniExclamationCircle size={5}/></span>
          <Trans key="ls_reconnection_interrupted" options={{ type: capitalize(props.shopType) }}/>
        </div>
        <span class="ml-7 xl:ml-1"><Trans key="ls_reconnection_please"/></span>
      </div>
      <button onClick={reconnect}
         class="w-full sm:w-min whitespace-nowrap flex items-center gap-2 justify-center rounded-md !border-gray-300 cursor-pointer !bg-white px-3.5 py-2 text-sm font-semibold text-gray-800 h-fit shadow-sm hover:!bg-gray-200 transition duration-200">
        <MiniArrowsRightLeft size={4} />
        <Trans key="ls_reconnection_action"/>
      </button>
    </div>
  )
}

export default ReconnectionNeededBanner
