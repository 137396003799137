import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'
import { type Component, Show } from 'solid-js'
import ProgressBar from '../shared/components/ProgressBar'
import { type CogsSummary } from '../../features/product/domain/models/cogs-summary'
import Button, { ButtonStyle } from '../shared/components/Button'

export interface MainMissingCogsBannerProps {
  readonly summary: CogsSummary
  readonly simplified?: true
  readonly action: () => void
}

const MainMissingCogsBanner: Component<MainMissingCogsBannerProps> = (props) => {
  const progress = (): number => Math.floor(props.summary.productCogsRatio * 100)

  return (
    <Show when={props.summary.totalCogsIssues > 0}>
      <div class="text-gray relative flex flex-col bg-white rounded-lg px-6 py-4 w-full">
        <div class="flex flex-col sm:flex-row items-start w-full">
          <div class="hidden xl:block xl:w-[104px]">
            <img src="/images/yuppie-bust.png" class="absolute hidden sm:block sm:w-36 xl:w-[104px] sm:top-auto sm:left-2 xl:top-auto xl:bottom-0 xl:left-0"/>
          </div>
          <div class="flex flex-col w-full">
            <div class="flex flex-col xl:flex-row xl:items-center gap-3 w-full">
              <div class="flex flex-col gap-0.5 justify-center w-full">
                <p class="text-sm m-0 font-semibold">
                  <Trans key="ls_cogs_num_without_cogs" />
                </p>
                <p class="text-sm m-0">
                  <Show when={props.simplified} fallback={
                    <span innerHTML={t('ls_cogs_progress_full', {
                      num: props.summary.totalCogsIssues,
                      progress: progress()
                    })}/>
                  }>
                    <span innerHTML={t('ls_cogs_progress', { num: props.summary.totalCogsIssues })}/>
                  </Show>
                </p>
              </div>
              <Button style={ButtonStyle.White} action={props.action}>
                <Trans key={'ls_cogs_fix_now'} />
              </Button>
            </div>
            <div class="w-full mt-2 flex items-center gap-2 min-w-0">
              <ProgressBar progress={progress()}/>
              <span class="text-xs text-gray-700 font-medium whitespace-nowrap">🎉 <Trans key={'ls_cogs_accurate'}/></span>
            </div>
          </div>
        </div>
      </div>
    </Show>
  )
}

export default MainMissingCogsBanner
