import { type Component } from 'solid-js'
import logoAnimation from '../assets/animations/logo-animated.json'
import logoLetters from '../assets/images/profit-tree-logo-letters.svg'
import LottieAnimation from './shared/components/LottieAnimation'

const LoadingScreen: Component = () => {
  return (
    <div class="w-full bg-blue_gray-100 flex items-center gap-4 justify-center h-full">
      <LottieAnimation animationData={logoAnimation} width="35px" />
      <img src={logoLetters} alt="Profit Tree" class="2-36" />
    </div>
  )
}

export default LoadingScreen
