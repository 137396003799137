import { type Component, type JSX, onCleanup, Show } from 'solid-js'
import MiniExclamationTriangle from '../../assets/heroicons/MiniExclamationTriangle'
import MiniCheckCircle from '../../assets/heroicons/MiniCheckCircle'
import { type CogsManagement } from '../../features/product/domain/models/cogs-management'
import MiniPencil from '../../assets/heroicons/MiniPencil'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'
import MiniChevronRight from '../../assets/heroicons/MiniChevronRight'
import MiniInformationCircle from '../../assets/heroicons/MiniInformationCircle'
import Tooltip from '../shared/components/Tooltip'
import { type Position } from '../../shared/helpers/dom.functions'
import { useTooltip } from '../../shared/helpers/useTooltip'

export interface MissingCogsCellProps {
  readonly cogs: CogsManagement
  readonly isDisabled?: boolean
  readonly hideActions?: boolean
  readonly onEditSingleCogs: () => void
}

const MissingCogsCell: Component<MissingCogsCellProps> = (props) => {
  const attribution = props.cogs.attributions
  const isMissingCogs = attribution.missing > 0
  const showInfo =
    attribution.profittree === 0 &&
    attribution.missing === 0 &&
    (attribution.printful > 0 || attribution.printify > 0)
  const showLinkToCogsHistory = !isMissingCogs && !showInfo

  let title: string | undefined
  let description: string | undefined

  if (props.cogs.isPod) {
    title = 'ls_cogs_automatic'
    description = 'ls_cogs_automatic_description'
  } else if (props.cogs.attributions.missing > 0) {
    description = 'ls_cogs_missing_tooltip'
  }

  const tooltipComponent: Component<{ position?: Position }> | undefined = description
    ? (props) => <Tooltip title={title} text={description!} position={props.position} />
    : undefined

  const { showTooltip, hideTooltip } = useTooltip({ tooltipComponent }) // Usa el hook

  onCleanup(hideTooltip)

  const CellContent = (): JSX.Element => {
    return (
      <div
        class={`flex gap-1.5 items-center w-fit rounded-full px-2 h-5 ${
          isMissingCogs ? 'bg-red-50' : 'bg-green-50'
        }`}
      >
        <Show when={isMissingCogs}>
          <span class="text-red-500"><MiniExclamationTriangle /></span>
        </Show>
        <Show when={!isMissingCogs}>
          <span class="text-green-500"><MiniCheckCircle size={4} /></span>
        </Show>
        <span class="text-gray-900 text-xs">{attribution.missing}</span>
      </div>
    )
  }

  return (
    <div class="flex items-center justify-center">
      <Show when={isMissingCogs}>
        <button
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          class="flex items-center justify-center text-gray-400 gap-4"
          disabled={props.isDisabled}
          onClick={props.onEditSingleCogs}
        >
          {CellContent()}
          <Show when={!props.hideActions}>
            <MiniPencil />
          </Show>
        </button>
      </Show>
      <Show when={showLinkToCogsHistory}>
        <A
          href={AppRoutes.ProductCogs(props.cogs.product.id)}
          class="flex items-center justify-center text-gray-400 gap-4"
        >
          {CellContent()}
          <Show when={!props.hideActions}>
            <MiniChevronRight />
          </Show>
        </A>
      </Show>
      <Show when={showInfo}>
        <div
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          class="flex items-center justify-center text-gray-400 gap-4"
        >
          {CellContent()}
          <MiniInformationCircle size={4} />
        </div>
      </Show>
    </div>
  )
}

export default MissingCogsCell
