import type { Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { Show } from 'solid-js'
import MiniTag from '../../assets/heroicons/MiniTag'
import { type Product } from '../../features/product/domain/models/product'

export interface ViewOnEtsyCellProps {
  readonly product: Product
  readonly numListings: number
}

const ProductListingCell: Component<ViewOnEtsyCellProps> = (props) => {
  const isListingDeleted = props.product.isListingDeleted()
  const listingTitle = isListingDeleted
    ? 'ls_listing_deleted'
    : props.product.listing.title

  return (
    <>
      <div class="flex gap-1 justify-between mb-1 items-center">
        <div class={`${isListingDeleted ? 'text-gray-500' : 'text-gray-900'} line-clamp-1`}><Trans key={listingTitle}/></div>
        <Show when={props.numListings > 1}>
          <div class="bg-gray-200 h-6 flex items-center px-1 rounded-md">
            <span class="text-gray-500 text-xs font-semibold whitespace-nowrap">+{props.numListings - 1}</span>
          </div>
        </Show>
      </div>
      <div class="flex items-center gap-1 text-xs text-gray-500">
        <span class="min-w-4"><MiniTag size={3}/></span>
        <span class="line-clamp-1" innerHTML={props.product.variantName} />
      </div>
    </>
  )
}

export default ProductListingCell
