import { TransProvider } from '@mbarzda/solid-i18next'
import { initializePaddle } from '@paddle/paddle-js'
import * as Sentry from '@sentry/browser'
import { Navigate, Outlet, Route, Routes, useLocation, useSearchParams } from '@solidjs/router'
import i18next from 'i18next'
import { DEV, Show, createEffect, createSignal, onMount, type Component } from 'solid-js'
import './App.scss'
import translations from './assets/locales/translations.json'
import { MainModule } from './features/main-module'
import MainLayout from './components/layout/MainLayout'
import SimpleLayout from './components/layout/SimpleLayout'
import AccountSettings from './components/account-settings/AccountSettings'
import CogsManagement from './components/cogs-management/CogsManagement'
import Cogs from './components/cogs/Cogs'
import Dashboard from './components/dashboard/Dashboard'
import Education from './components/education/Education'
import Integrations from './components/integrations/Integrations'
import Listings from './components/listings/Listings'
import ListingsLocked from './components/listings/ListingsLocked'
import Listing from './components/listings/listing/Listing'
import ListingLocked from './components/listings/listing/ListingLocked'
import Orders from './components/orders/Orders'
import PaymentScreen from './components/payment/PaymentScreen'
import Products from './components/products/Products'
import ProductsLocked from './components/products/ProductsLocked'
import Product from './components/products/product/Product'
import ProductLocked from './components/products/product/ProductLocked'
import Settings from './components/settings/Settings'
import {
  AuthProvider,
  auth0Config,
  useAuth,
  useHandleAuthentication
} from './shared/providers/auth-provider'
import { UserProvider, useUser } from './shared/providers/user-provider'
import ProtectedRoute from './shared/guards/protected-route'
import SubscribedGuard from './shared/guards/subscribed-guard'
import UnsubscribedGuard from './shared/guards/unsubscribed-guard'
import { AppRoutes } from './shared/app-routes'
import { useNumberFormatter } from './shared/helpers/currency-formatter'
import { ErrorProvider } from './shared/providers/error.provider'
import { PaddleProvider, usePaddle } from './shared/providers/paddle.provider'
import { PeriodProvider } from './shared/providers/period.provider'
import { ShopProvider } from './shared/providers/shop.provider'
import { TrackingProvider, useTracking } from './shared/providers/tracking.provider'
import Ads from './components/advertisement/Ads'
import SubscriptionLoadingScreen from './components/SubscriptionLoadingScreen'
import LoadingScreen from './components/LoadingScreen'

const App: Component = () => {
  const [isAuthenticated, setIsAuthenticated] = createSignal(false)
  const [isLoading, setIsLoading] = createSignal(true)

  const { auth } = useAuth()
  const { setCurrentUser, userPurchases, setUserPurchases } = useUser()
  const { paddle, setPaddle } = usePaddle()
  const handleAuthentication = useHandleAuthentication()
  const location = useLocation()
  const { trackUser } = useTracking()
  const { currencyFormatter } = useNumberFormatter()
  const [searchParams] = useSearchParams()

  onMount(async () => {
    currencyFormatter() // init locale
    void initPaddle()
    handleAuthentication()
      .then(async () => {
        const authState = await auth.isAuthenticated()
        setIsAuthenticated(authState)

        if (!authState) {
          await auth.loginWithRedirect(auth0Config(location.search))
          return
        }

        await initSubscription()
        await initUser()

        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  })

  const initPaddle = async (): Promise<void> => {
    try {
      const paddle = await initializePaddle({
        token: import.meta.env.VITE_PADDLE_TOKEN,
        environment: import.meta.env.VITE_PADDLE_ENV,
        checkout: {
          settings: {
            allowLogout: false
          }
        }
      })

      setPaddle(paddle)
    } catch (error) {
      Sentry.captureMessage('Paddle initialization failed', { extra: { error } })
    }
  }

  const getUtmParams = (): Record<string, string> | undefined => {
    const utmParams: Record<string, string> = {}

    Object.entries(searchParams).forEach(([key, value]) => {
      if (key.startsWith('utm_')) {
        utmParams[key] = value
      }
    })

    return Object.keys(utmParams).length ? utmParams : undefined
  }

  const initUser = async (): Promise<void> => {
    const getCurrentUser = MainModule.getAuthComponent().provideGetCurrentUser()
    const updateCurrentUser = MainModule.getAuthComponent().provideUpdateCurrentUser()
    const user = await getCurrentUser.execute(true)
    try {
      void updateCurrentUser.execute({ utms: getUtmParams() })
    } catch (e) {
      Sentry.captureException(e)
    }

    if (!DEV) {
      void window.profitwell('start', { user_email: user.email })
    }

    paddle()?.Update({
      pwCustomer: {
        id: user.customerId
      }
    })

    setCurrentUser(user)
    trackUser(user, userPurchases())
    Sentry.setUser({ id: user.id })
  }

  const initSubscription = async (): Promise<void> => {
    try {
      const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()
      const purchases = await getPurchases.execute()
      setUserPurchases(purchases)
    } catch (error) {
      console.error(error)
    }
  }

  createEffect(async () => {
    await i18next
      .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: true
        },
        resources: Object.fromEntries(
          Object.entries(translations).map(([lang, translation]) => [
            lang,
            { translation }
          ])
        )
      })
  })

  return (
    <Show when={!isLoading() && isAuthenticated()} fallback={<LoadingScreen />}>
      <TransProvider>
        <TrackingProvider>
          <AuthProvider>
            <UserProvider>
              <PaddleProvider>
                <Routes>
                  <Route path={AppRoutes.LoadingAccount()} component={SubscriptionLoadingScreen}/>
                  <Route path={AppRoutes.Payment()} element={<UnsubscribedGuard component={SimpleLayout}/>}>
                    <Route path={AppRoutes.AccountSettings()} component={AccountSettings}/>
                    <Route path="/" component={PaymentScreen}/>
                  </Route>
                  <Route path={AppRoutes.NoSubscriptionEducation()} element={<SimpleLayout />}>
                    <Route path="/" component={Education}/>
                  </Route>
                  <Route path="/" element={<SubscribedGuard component={() => <Outlet />}/>}>
                    <ErrorProvider>
                      <PeriodProvider>
                        <ShopProvider>
                          <Route path={AppRoutes.Subscription()} element={<ProtectedRoute component={SimpleLayout}/>}>
                            <Route path="/" component={PaymentScreen}/>
                          </Route>
                            <Route path="/" element={<Navigate href={AppRoutes.Dashboard()}/>}/>
                            <Route path="/" element={<ProtectedRoute component={MainLayout}/>}>
                            <Route path={AppRoutes.AccountSettings()} component={AccountSettings}/>
                            <Route path={AppRoutes.Advertisement()} component={Ads}/>
                            <Route path={AppRoutes.Settings()} component={Settings}/>
                            <Route path={AppRoutes.Dashboard()} component={Dashboard}/>
                            <Route path={AppRoutes.Education()} element={<Education/>} />
                            <Route path={AppRoutes.Integrations()} component={Integrations}/>
                            <Route path={AppRoutes.Orders()} component={Orders}/>
                            <Route path={AppRoutes.CogsManagement()} component={CogsManagement}/>
                            <Route path={AppRoutes.ProductCogsPattern()} component={Cogs}/>
                            <Show when={userPurchases()?.isPro}>
                              <Route path={AppRoutes.Products()} component={Products}/>
                              <Route path={AppRoutes.ProductPattern()} component={Product}/>
                              <Route path={AppRoutes.Listings()} component={Listings}/>
                              <Route path={AppRoutes.ListingPattern()} component={Listing}/>
                            </Show>
                            <Show when={!userPurchases()?.isPro}>
                              <Route path={AppRoutes.Products()} component={ProductsLocked}/>
                              <Route path={AppRoutes.ProductPattern()} component={ProductLocked}/>
                              <Route path={AppRoutes.Listings()} component={ListingsLocked}/>
                              <Route path={AppRoutes.ListingPattern()} component={ListingLocked}/>
                            </Show>
                          </Route>
                        </ShopProvider>
                      </PeriodProvider>
                    </ErrorProvider>
                  </Route>
                </Routes>
              </PaddleProvider>
            </UserProvider>
          </AuthProvider>
        </TrackingProvider>
      </TransProvider>
    </Show>
  )
}

export default App
