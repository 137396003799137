import { type Component } from 'solid-js'

export interface CheckboxCellProps {
  readonly isSelected?: boolean
  readonly onValueChange: (value: boolean) => void
  readonly setSelectorRef?: (el: HTMLInputElement) => void
}

const CheckboxCell: Component<CheckboxCellProps> = (props) => {
  return (
    <div class="flex items-center gap-2 text-sm">
      <input
        type="checkbox"
        ref={el => { props.setSelectorRef?.(el) }}
        checked={props.isSelected ?? false}
        class="form-checkbox h-4 w-4 text-japanese-600 transition duration-150 ease-in-out rounded-[4px] border-gray-400"
        onChange={(e) => { props.onValueChange(e.currentTarget.checked) }}
      />
    </div>
  )
}

export default CheckboxCell
