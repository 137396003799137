import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type CogsUpdateStatusEntity } from '../../data/entities/cogs-update-status.entity'
import { CogsUpdateReport, CogsUpdateStatus } from '../models/cogs-update-status'

export class CogsUpdateStatusEntityToCogsUpdateStatusMapper implements Mapper<CogsUpdateStatusEntity, CogsUpdateStatus> {
  public map(from: CogsUpdateStatusEntity): CogsUpdateStatus {
    return new CogsUpdateStatus(
      from.id,
      from.status,
      from.filename,
      from.report
        ? new CogsUpdateReport(
          from.report.success,
          from.report.products_not_found,
          from.report.products_pod,
          from.report.total
        )
        : undefined
    )
  }
}
