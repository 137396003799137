import { type Component, onCleanup, Show } from 'solid-js'
import { type KPI } from '../../../features/analytics/domain/models/kpi'
import ProgressRatio from './ProgressRatio'
import BreakdownBox from './BreakdownBox'
import { type Position } from '../../../shared/helpers/dom.functions'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'
import { Trans } from '@mbarzda/solid-i18next'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../../shared/app-routes'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'
import { useTooltip } from '../../../shared/helpers/useTooltip'

export interface ValueProgressCellProps {
  readonly kpi: KPI
  readonly warning?: boolean
  readonly productId?: number
}

const ValueProgressCell: Component<ValueProgressCellProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()

  const tooltipComponent = props.kpi.breakdown
    ? (positionProps: { position?: Position }) => <BreakdownBox breakdown={props.kpi.breakdown!} {...positionProps} />
    : undefined

  const { showTooltip, hideTooltip } = useTooltip({ tooltipComponent })

  onCleanup(hideTooltip)

  return (
    <div class="relative">
      <div class="flex gap-2 items-center">
        <Show when={props.warning && props.productId}>{(productId) => (
          <A href={AppRoutes.ProductCogs(productId())} class="cursor-pointer text-red-500">
            <MiniExclamationTriangle />
          </A>
        )}
        </Show>
        <Show when={props.kpi.value !== undefined} fallback={
          <span class="text-gray-500"><Trans key="ls_generic_n_a" /></span>
        }>
          <span onMouseEnter={(event) => { showTooltip(event) }} onMouseLeave={hideTooltip}>
            {formatNumericValue(props.kpi.type, props.kpi.value)}
          </span>
        </Show>
      </div>
      <Show when={props.kpi.value !== undefined}>
        <ProgressRatio value={props.kpi.progressRatio} prevValue={props.kpi.prevValue} type={props.kpi.type} kpi={props.kpi.id} />
      </Show>
    </div>
  )
}

export default ValueProgressCell
