import 'flowbite'
import { createEffect, createSignal, onCleanup, onMount, type Component } from 'solid-js'
import MiniCalendarDays from '../../assets/heroicons/MiniCalendarDays'
import { useDateFormatter } from '../../shared/helpers/date-formatter'
import { getDateFormatString } from '../../shared/helpers/date.functions'
// @ts-expect-error Flowbite does not provide types
import DateRangePicker from 'flowbite-datepicker/DateRangePicker'
import { PeriodType } from '../../features/shared/period-type'
import { clickOutsideCalendar } from '../../shared/directives/click-outside-calendar'
import { usePeriods } from '../../shared/providers/period.provider'
import Button, { ButtonStyle } from '../shared/components/Button'
import { Trans } from '@mbarzda/solid-i18next'

export interface CustomPeriodSelectorProps {
  readonly close: () => void
  readonly submit: (start: Date, end: Date) => void
  readonly firstAllowedDate?: Date
  readonly lastAllowedDate?: Date
}

const CustomPeriodSelector: Component<CustomPeriodSelectorProps> = (props) => {
  const { shortDateFormatter } = useDateFormatter()
  const { periods } = usePeriods()
  const [selectedDates, setSelectedDates] = createSignal<Array<Date | undefined>>([undefined, undefined])

  let calendar: DateRangePicker | null

  const lastAllowedDate = props.lastAllowedDate ?? new Date()
  const firstOf2023 = new Date('2023-01-01')
  const firstAllowedDate = props.firstAllowedDate ?? firstOf2023

  const BaseCalendarOptions = {
    format: getDateFormatString(),
    orientation: 'bottom',
    autohide: false,
    rangePicker: true,
    allowOneSidedRange: true
  }

  onMount(() => {
    const inputs = document.getElementById('inputs')
    calendar = new DateRangePicker(inputs, {
      ...BaseCalendarOptions,
      minDate: shortDateFormatter(firstAllowedDate),
      maxDate: shortDateFormatter(lastAllowedDate)
    })

    const interval = setInterval(() => {
      const dates: Date[] = calendar?.getDates()
      if (dates && dates.some((date, index) => date?.getTime() !== selectedDates()[index]?.getTime())) {
        setSelectedDates(dates)
      }
    }, 300)
    onCleanup(() => { clearInterval(interval) })
  })

  createEffect(() => {
    const [start, end] = selectedDates()
    if (start && !end) {
      const yearAfterStart = new Date(start)
      yearAfterStart.setFullYear(yearAfterStart.getFullYear() + 1)
      const maxDate = yearAfterStart > lastAllowedDate ? lastAllowedDate : yearAfterStart
      calendar.setOptions({
        ...BaseCalendarOptions,
        maxDate
      })
    }

    if (!start && end) {
      const yearBeforeEnd = new Date(end)
      yearBeforeEnd.setFullYear(yearBeforeEnd.getFullYear() - 1)
      const minDate = yearBeforeEnd < firstAllowedDate ? firstAllowedDate : yearBeforeEnd
      calendar.setOptions({
        ...BaseCalendarOptions,
        minDate
      })
    }
  })

  const handleSubmit = (): void => {
    const [start, end] = calendar.getDates()
    if (start && end) {
      props.submit(start, end)
    }
  }

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  const getFormattedDateIfApplies = (timestap: number | undefined): string => {
    if (periods().type !== PeriodType.Custom) return ''
    return timestap ? shortDateFormatter(new Date(timestap * 1000)) : ''
  }

  return (
    <div class="p-6 text-gray-800 flex gap-6 items-center" ref={el => { clickOutsideCalendar(el, props.close) }}>
      <form id="inputs" class="flex gap-6 items-center" onKeyDown={handleKeyDown}>
        <div class="flex gap-3 items-center">
          <div class="relative rounded-md shadow-sm">
            <div class="text-gray-900 pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
              <MiniCalendarDays/>
            </div>
            <input id="startDate"
                  name="range-start"
                  readOnly
                  placeholder='Start date'
                  type="text"
                  value={getFormattedDateIfApplies(periods().current.from)}
                  class="block w-40 !text-sm !rounded-lg !border-gray-300 py-1.5 !pl-12 text-gray-900 placeholder:text-gray-400 shadow-sm focus:!ring-inset focus:!ring-japanese-700 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 disabled:border-none"/>
          </div>
          -
          <div class="relative rounded-md shadow-sm">
              <div class="text-gray-900 pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                <MiniCalendarDays/>
              </div>
            <input id="endDate"
                  name="range-end"
                  readOnly
                  placeholder='End date'
                  type="text"
                  value={getFormattedDateIfApplies(periods().current.to)}
                  class="block w-40  !text-sm !rounded-lg !border-gray-300 py-1.5 !pl-12 text-gray-900 placeholder:text-gray-400 shadow-sm focus:!ring-inset focus:!ring-japanese-700 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 disabled:border-none"/>
          </div>
        </div>
      </form>
      <Button style={ButtonStyle.Primary} action={handleSubmit} disabled={
        !(selectedDates()?.every(value => !!value))
      }>
        <Trans key="ls_generic_apply" />
      </Button>
    </div>
  )
}

export default CustomPeriodSelector
