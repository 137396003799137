import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type IntegrationEntity, type ShopEntity } from '../../data/entities/shop.entity'
import { Integration, Shop } from '../models/shop'
import { mapTimestampToDate } from '../../../shared/map-timestamp-to-date'

const mapIntegrationEntityToIntegration = (from: IntegrationEntity): Integration => new Integration(
  from.id ?? undefined,
  from.name ?? undefined,
  from.fetch_status,
  from.invalid_token
)

export class ShopEntityToShopMapper implements Mapper<ShopEntity, Shop> {
  public map (from: ShopEntity): Shop {
    return new Shop(
      from.shop_id,
      from.name,
      from.url,
      from.image_url,
      from.first_fetch,
      from.full_fetch,
      from.is_updating,
      from.invalid_token,
      {
        printful: from.integrations.printful ? mapIntegrationEntityToIntegration(from.integrations.printful) : undefined,
        printify: from.integrations.printify ? mapIntegrationEntityToIntegration(from.integrations.printify) : undefined
      },
      from.currency_code,
      mapTimestampToDate(from.last_listing_fetch_timestamp),
      mapTimestampToDate(from.last_report_fetch_timestamp)
    )
  }
}
