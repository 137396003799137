import { type Component, type JSX } from 'solid-js'

export enum ButtonStyle {
  Primary,
  Secondary,
  White,
  Etsy,
  Red
}

export interface ButtonProps {
  readonly style: ButtonStyle
  readonly action: (e: MouseEvent) => void
  readonly disabled?: boolean
  readonly width?: 'full' | 'fit'
  readonly class?: string
  readonly children?: JSX.Element
}

const Button: Component<ButtonProps> = (props) => {
  let btnClass = 'btn--primary'
  const width = props.width === 'full' ? 'w-full' : 'min-w-fit'

  switch (props.style) {
    case ButtonStyle.Secondary:
      btnClass = 'btn--secondary'
      break
    case ButtonStyle.White:
      btnClass = 'btn--white'
      break
    case ButtonStyle.Etsy:
      btnClass = 'btn--etsy'
      break
    case ButtonStyle.Red:
      btnClass = 'btn--red'
      break
  }

  return (
    <button onClick={props.action} class={`btn ${btnClass} ${width} ${props.class ?? ''}`} disabled={props.disabled}>
      { props.children }
    </button>
  )
}

export default Button
