import { type Component, createEffect, createSignal } from 'solid-js'
import SectionCard from './SectionCard'
import MiniUserCircle from '../../assets/heroicons/MiniUserCircle'
import TranslateWithAction from '../shared/components/TranslateWithAction'
import { Trans } from '@mbarzda/solid-i18next'
import { MainModule } from '../../features/main-module'
import { useUser } from '../../shared/providers/user-provider'

const SubscribedSectionCard: Component = () => {
  const [isLoading, setIsLoading] = createSignal(false)
  const { setCurrentUser, currentUser } = useUser()
  const [name, setName] = createSignal<string | undefined>()
  const [nameError, setNameError] = createSignal<string>('')

  const updateUser = MainModule.getAuthComponent().provideUpdateCurrentUser()

  createEffect(() => {
    setName(currentUser()?.name)
  })

  const handleSubmit = async (event: Event): Promise<void> => {
    event.preventDefault()

    const value = name()
    validateMandatory(value)

    if (nameError()) {
      return
    }
    setNameError('')

    setIsLoading(true)
    const newUser = await updateUser.execute({ name: value })
    setCurrentUser(newUser)
    setIsLoading(false)
  }

  const validateMandatory = (value?: string): boolean => {
    if (value) {
      setNameError('')
      return true
    }
    setNameError('empty')
    return false
  }

  return (
    <SectionCard icon={<MiniUserCircle/>} title='ls_profile_title' styleNumColumns='half'>
      <div class="flex gap-6 sm:gap-9 flex-col sm:flex-row items-center sm:items-start">
        <div class="h-24 w-36 flex justify-center">
          <img class="h-24 min-w-24 w-24 rounded-full" referrerPolicy="no-referrer" src={currentUser()?.pictureUrl} alt=""/>
        </div>
        <div class="flex flex-col w-full gap-4 flex-grow">
          <div>
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
              <Trans key="ls_generic_name" />
            </label>
            <input type="text" name="name" id="name"
                   class="block w-full !text-sm !rounded-lg !border-gray-300 py-1.5 !pr-7 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:!ring-inset focus:!ring-japanese-700"
                   value={currentUser()?.name} aria-describedby="user-name"
                   onInput={(e) => {
                     const value = (e.target as HTMLInputElement).value
                     validateMandatory(value)
                     setName(value)
                   }}/>
            <span class="block text-red-600 text-xs h-2">
              {nameError() &&
                <Trans key={`ls_input_error_${nameError()}`}/>
              }
            </span>
          </div>
          <div>
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
              <Trans key="ls_generic_email" />
            </label>
            <input type="text" name="email" id="email" disabled
                   class="block w-full !text-sm !rounded-lg !border-gray-300 py-1.5 !pr-7 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 disabled:border-none"
                   placeholder={currentUser()?.email} aria-describedby="user-name"/>
            <span class="text-xs text-gray-500 mt-1">
              <TranslateWithAction key='ls_profile_change_email'
                                   action={() => {
                                     window.Intercom('show')
                                   }}
              />
            </span>
          </div>
          <div class="flex justify-end">
            <button class="w-fit flex items-center disabled:bg-gray-300 disabled:text-white px-3 py-2 justify-center rounded-md bg-japanese-600 text-sm font-semibold text-white shadow-sm hover:bg-japanese-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-japanese-600 duration-200"
              onClick={(e) => {
                void handleSubmit(e)
              }}
              disabled={isLoading()}
            >
              <Trans key="ls_generic_update"/>
            </button>
          </div>
        </div>
      </div>
    </SectionCard>
  )
}

export default SubscribedSectionCard
