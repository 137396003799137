import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { UpdateCogsQuery } from '../../data/queries/update-cogs.query'

export class UpdateCogsInteractor {
  constructor(
    private readonly updateCogs: PutInteractor<void>
  ) {
  }

  public async execute (
    cogsValue: number,
    productIds: number[],
    shopId: number
  ): Promise<void> {
    await this.updateCogs.execute(
      undefined,
      new UpdateCogsQuery(cogsValue, productIds, shopId)
    )
  }
}
