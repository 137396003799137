import { type Component, Show } from 'solid-js'
import ImageCell from '../shared/components/ImageCell'
import { AppRoutes } from '../../shared/app-routes'
import { A } from '@solidjs/router'
import NoListing from '../../assets/images/no-listing-sm.svg'
import { type Ad } from '../../features/product/domain/models/ad'
import ViewListingOnEtsyCell from '../listings/ViewListingOnEtsyCell'
import ValueCell from '../shared/components/ValueCell'
import { NumericalDataType } from '../../shared/models/numerical-data-type'
import StatusCell from '../shared/components/StatusCell'
import TotalOrdersCell from '../shared/components/TotalOrdersCells'
import TotalSalesCell from '../shared/components/TotalSalesCells'
import BeroasCell from './BeroasCell'

export interface adTableRowProps {
  readonly ad: Ad
  readonly isLoading: () => boolean
}

const AdsTableRow: Component<adTableRowProps> = (props) => {
  const ad = props.ad

  return (
    <tr>
      <td class="py-3.5 pl-4 pr-3 text-sm font-medium sm:pl-6 min-w-[88px]">
        <A href={AppRoutes.Listing(ad.listing.id)}>
          <ImageCell imgUrl={ad.listing.imgUrls?.sm ? ad.listing.imgUrls.sm : NoListing}/>
        </A>
      </td>
      <td class="tableCell min-w-[240px]">
        <ViewListingOnEtsyCell listing={ad.listing}/>
      </td>
      <td class="tableCell min-w-[148px]">
        <StatusCell status={ad.synced ? 'ls_ads_status_synced' : 'ls_ads_status_outdated' } color={ad.synced ? 'green' : 'orange' } />
      </td>
      <td class="tableCell min-w-[148px]">
        <ValueCell value={ad.adSpent} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[124px] font-bold">
        <ValueCell value={ad.tacos} type={NumericalDataType.Percentage}/>
      </td>
      <Show when={false}>
        <td class="tableCell min-w-[124px]">
          <BeroasCell value={ad.breakEvenRoas} missingCogs={ad.missingCogs} />
        </td>
      </Show>
      <td class="tableCell min-w-[124px]">
        <ValueCell value={ad.roas} type={NumericalDataType.Ratio}/>
      </td>
      <td class="tableCell min-w-[148px]">
        <TotalSalesCell type={NumericalDataType.Currency} breakdown={ad.sales}/>
      </td>
      <td class="tableCell min-w-[148px]">
        <TotalOrdersCell type={NumericalDataType.Units} breakdown={ad.orders}/>
      </td>
    </tr>
  )
}

export default AdsTableRow
