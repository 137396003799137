import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type CogsManagementFilters } from '../../../../components/cogs-management/CogsManagementFilters'
import { ExportCogsManagementQuery } from '../../data/queries/export-cogs-management.query'
import type { SortDirection } from '../../../../shared/models/sort-direction'

export class ExportCogsManagementInteractor {
  constructor(
    private readonly exportCogs: GetInteractor<string>
  ) {
  }

  public async execute (
    shopId: number,
    filters: CogsManagementFilters,
    orderBy?: string,
    searchTerm?: string
  ): Promise<string> {
    const sortBy = orderBy?.split(':')[0]
    const sortDirection = orderBy?.split(':')[1] as SortDirection

    return await this.exportCogs.execute(new ExportCogsManagementQuery(shopId, filters, sortBy, sortDirection, searchTerm))
  }
}
