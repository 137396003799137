export class CogsUpdateReport {
  constructor(
    public readonly success: number,
    public readonly productsNotFound: number,
    public readonly productsPod: number,
    public readonly total: number
  ) {
  }
}

export class CogsUpdateStatus {
  constructor(
    public readonly id: string,
    public readonly status: 'pending' | 'completed',
    public readonly filename: string,
    public readonly report: CogsUpdateReport | undefined
  ) {
  }
}
