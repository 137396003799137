import { type KPIType } from './kpi'
import { type TimeSeriesData } from '../../../../components/shared/timeseries/linear-timeseries-vm'

export class Timeseries {
  constructor(
    public readonly dateTimes: Date[],
    public readonly series: Partial<Record<KPIType, TimeSeriesData>>
  ) {
  }
}
