import { GetCogsSummaryQuery } from '../../data/queries/get-cogs-summary.query'
import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type CogsSummary } from '../models/cogs-summary'

export class GetCogsSummaryInteractor {
  constructor(
    private readonly getCogsSummary: GetInteractor<CogsSummary>
  ) {
  }

  public async execute (
    shopId: number,
    startTime?: number,
    endTime?: number
  ): Promise<CogsSummary> {
    return await this.getCogsSummary.execute(new GetCogsSummaryQuery(shopId, startTime, endTime))
  }
}
