import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type CogsSummaryEntity } from '../../data/entities/cogs-summary.entity'
import { CogsSummary } from '../models/cogs-summary'

export class CogsSummaryEntityToCogsSummaryMapper implements Mapper<CogsSummaryEntity, CogsSummary> {
  public map(from: CogsSummaryEntity): CogsSummary {
    return new CogsSummary(
      from.total_products,
      from.total_cogs_issues,
      from.product_cogs_ratio
    )
  }
}
