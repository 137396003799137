import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type Product } from '../models/product'
import { type ProductEntity } from '../../data/entities/product.entity'
import { type CogsManagementEntity } from '../../data/entities/cogs-management.entity'
import { CogsManagement } from '../models/cogs-management'

export class CogsManagementEntityToCogsManagementMapper implements Mapper<CogsManagementEntity, CogsManagement> {
  constructor(
    private readonly productMapper: Mapper<ProductEntity, Product>
  ) {
  }

  public map(from: CogsManagementEntity): CogsManagement {
    return new CogsManagement(
      this.productMapper.map(from.product),
      from.listings_count,
      from.orders_count,
      from.cogs_attribution,
      from.currency,
      from.last_attributed_cogs ?? undefined
    )
  }
}
