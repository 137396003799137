import { type Product } from './product'

export interface CogsAttribution {
  printify: number
  printful: number
  profittree: number
  missing: number
}

export class CogsManagement {
  constructor(
    public readonly product: Product,
    public readonly numListings: number,
    public readonly numOrders: number,
    public readonly attributions: CogsAttribution,
    public readonly currency: string,
    public readonly lastAttributedCogs?: number
  ) {
  }

  public get isPod(): boolean {
    const { printify, printful, profittree, missing } = this.attributions

    const conditionsMet = [
      printify > 0 ? 1 : 0,
      printful > 0 ? 1 : 0,
      profittree > 0 ? 1 : 0,
      missing > 0 ? 1 : 0
    ].reduce((sum, val) => sum + val, 0)

    return conditionsMet === 1 && (printify > 0 || printful > 0)
  }
}
