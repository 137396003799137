import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { HttpParameters } from '@coris-ts/data/parameter-type'

export class GetCurrentUserQuery extends GetNetworkQuery {
  constructor (private readonly isOnLaunch?: boolean
  ) {
    super(ApiEndpoint.Me)
  }

  public get queryParameters(): HttpParameters {
    return this.isOnLaunch ? { is_launch: this.isOnLaunch } : {}
  }
}
