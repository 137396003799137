import { type Component, createEffect, createSignal, For, Show } from 'solid-js'
import MiniChevronDown from '../../assets/heroicons/MiniChevronDown'
import { clickOutside } from '../../shared/directives/click-outside'
import { MainModule } from '../../features/main-module'
import MiniPlus from '../../assets/heroicons/MiniPlus'
import { Trans } from '@mbarzda/solid-i18next'
import { useShop } from '../../shared/providers/shop.provider'
import ConnectEtsyButton from '../shared/components/ConnectEtsyButton'
import { type Shop } from '../../features/shop/domain/models/shop'
import { useTracking } from '../../shared/providers/tracking.provider'
import { AppRoutes } from '../../shared/app-routes'
import { useNavigate } from '@solidjs/router'
import ExclamationTriangle from '../../assets/heroicons/ExclamationTriangle'
import { useUser } from '../../shared/providers/user-provider'

enum SelectState {
  Opening,
  Open,
  Closing,
  Closed
}

export interface ShopSelectorProps {
  readonly onItemSelected: () => void
}

const ShopSelector: Component<ShopSelectorProps> = (props) => {
  const [selectState, setSelectState] = createSignal(SelectState.Closed)

  const { userPurchases } = useUser()
  const { shops, selectedShopId, setSelectedShop } = useShop()
  const { trackEvent } = useTracking()
  const navigate = useNavigate()

  const getEtsyAuthUrl = MainModule.getShopComponent().provideGetEtsyAuthUrl()

  createEffect(() => {
    const shopList = shops()
    if (!shopList) return
    const shop = shopList.find((shop) => shop.id === selectedShopId())
    if (shop) {
      setSelectedShop(shop)
    }
  })

  const openSelector = (): void => {
    if (selectState() !== SelectState.Open && selectState() !== SelectState.Opening) {
      setSelectState(SelectState.Opening)
      setTimeout(() => {
        setSelectState(SelectState.Open)
      }, 10)
    }
  }

  const closeSelector = (): void => {
    if (selectState() !== SelectState.Closed && selectState() !== SelectState.Closing) {
      setSelectState(SelectState.Closing)
      setTimeout(() => {
        setSelectState(SelectState.Closed)
      }, 300)
    }
  }

  const onActionOptionSelected = (shop: Shop): void => {
    if (!userPurchases()?.canHaveMultipleShops) {
      navigate(AppRoutes.Settings())
      return
    }

    setSelectedShop(shop)
    closeSelector()
    props.onItemSelected()
  }

  const toggleMenu = (): void => {
    if (selectState() === SelectState.Open) {
      closeSelector()
    }
    if (selectState() === SelectState.Closed) {
      openSelector()
    }
  }

  const onActionAddNewShop = async (): Promise<void> => {
    if (!userPurchases()?.isPro) {
      navigate(AppRoutes.Settings())
      return
    }

    trackEvent('ETSY Connection', { status: 'Start' })
    window.location.href = await getEtsyAuthUrl.execute()
  }

  const currentShop = (): Shop | undefined => shops().find(shop => shop.id === selectedShopId())

  return (
    <>
      <Show when={shops().length}>
        <div class="relative mt-2" ref={el => {
          clickOutside(el, closeSelector)
        }}>
          <button
            tabindex={-1}
            onClick={toggleMenu}
            type="button"
            class={`${selectState() === SelectState.Open ? 'rounded-t-md !bg-white text-gray-900 ring-white' : 'rounded-md text-white ring-gray-700'} transition ease-in duration-100 ring-1 ring-inset relative w-full min-h-[48px] cursor-pointer p-2 pr-10 text-left shadow-sm focus:outline-none text-sm sm:leading-6`}
          >
            <span class="flex items-center gap-3">
              <Show when={currentShop()}>{(shop) => (
                <>
                  <img src={shop().imageUrl} alt="" class="rounded h-8 w-8 flex-shrink-0 "/>
                  <span class="block truncate">{shop().name}</span>
                </>
              )}</Show>
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <MiniChevronDown />
            </span>
          </button>

          <ul
            class={`${selectState() === SelectState.Closed ? 'hidden' : 'visible'} ${selectState() === SelectState.Open ? ' opacity-100 ' : ' opacity-0'}` + ' transition ease-in duration-100 scroll-auto absolute z-10 max-h-56 w-full overflow-auto rounded-b-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'}
            tabindex="-1"
            role="listbox"
          >
            <For each={shops()}>
              {(shop) => (
                <Show when={currentShop()?.id !== shop.id}>
                  <li
                    onClick={() => {
                      onActionOptionSelected(shop)
                    }}
                    class="hover:bg-gray-200 text-gray-900 cursor-pointer select-none p-2 pr-4"
                    role="option"
                  >
                    <div class="flex gap-3 items-center">
                      <img src={shop.imageUrl} alt={shop.name} class="h-8 w-8 flex-shrink-0 rounded" />
                      <span class="block truncate grow">
                        {shop.name}
                      </span>
                      <Show when={!userPurchases()?.canHaveMultipleShops}>
                        <span class="text-gray-500"><ExclamationTriangle /></span>
                      </Show>
                    </div>
                  </li>
                </Show>
              )}
            </For>
            <li
              onClick={onActionAddNewShop}
              class="hover:bg-gray-200 text-gray-900 cursor-pointer select-none p-2 pr-9"
              role="option"
            >
              <div class="flex gap-3 items-center">
                <div class="h-8 w-8 flex items-center justify-center">
                  <MiniPlus />
                </div>
                <span class="font-normal block truncate"><Trans key="ls_add_shop" /></span>
              </div>
            </li>
          </ul>
        </div>
      </Show>
      <Show when={shops().length === 0}>
        <div class="mt-2">
          <ConnectEtsyButton />
        </div>
      </Show>
    </>
  )
}

export default ShopSelector
