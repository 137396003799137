import { t } from 'i18next'
import { Show, type Component } from 'solid-js'
import MiniInformationCircle from '../../../assets/heroicons/MiniInformationCircle'
import { type Position } from '../../../shared/helpers/dom.functions'

export interface TooltipProps {
  readonly title: string | undefined
  readonly text: string
  readonly position?: Position
}

const Tooltip: Component<TooltipProps> = (props) => {
  return (
    <div
      class="flex flex-col gap-2 border-[1px] border-gray-200 w-[236px] text-gray-900 text-sm absolute bg-white p-4 shadow-lg rounded-lg break-words z-50"
      style={{
        left: `${props.position?.left ?? 0}px`,
        top: `${props.position?.top ?? 24}px`
      }}>
      <div class="flex gap-2" classList={{ 'font-bold': !!props.title }}>
        <span class="text-gray-400"><MiniInformationCircle/></span>
        {t(props.title ?? props.text)}
      </div>
      <Show when={props.title}>
        <span class="font-normal">{t(props.text)}</span>
      </Show>
    </div>
  )
}

export default Tooltip
