import {
  type Accessor,
  type Component,
  createSignal,
  For,
  type Setter,
  Show
} from 'solid-js'
import Stat, { type StatCustomStyles } from './Stat'
import styles from './MainStats.module.scss'
import { type KPIType, type KPIVM } from '../../../features/analytics/domain/models/kpi'
import type { Breakdown } from '../../../features/product/domain/models/breakdown'
import BreakdownBox from './BreakdownBox'
import MiniArrowRight from '../../../assets/heroicons/MiniArrowRight'

export interface MainStatsProps {
  readonly kpis: KPIVM[]
  readonly selectedKPI: Accessor<KPIType>
  readonly setSelectedKPI: Setter<KPIType>
}

const MainStats: Component<MainStatsProps> = (props) => {
  const { selectedKPI, setSelectedKPI } = props
  const [hovered, setHovered] = createSignal<KPIType>()

  const isKPISelected = (kpi: KPIVM): boolean => selectedKPI() === kpi.id
  const isLastElement = (idx: number): boolean => idx === props.kpis.length - 1

  const customKPIStyles: StatCustomStyles = {
    label: styles.customResponsiveSize__label,
    value: styles.customResponsiveSize__value,
    valueContainer: styles.customResponsiveSize__valueContainer,
    placeholderSize: 'sm'
  }

  return (
    <div>
      <dl class="flex flex-col sm:grid sm:grid-cols-2 xl:flex xl:flex-row justify-between items-center gap-2">
        <For each={props.kpis}>{(kpi, idx) => (
          <>
            <div
              onClick={() => { setSelectedKPI(kpi.id) }}
              onMouseEnter={() => setHovered(kpi.id)}
              onMouseLeave={() => { setHovered(undefined) }}
              class={`relative cursor-pointer w-full grow p-6 shadow rounded-lg ${isKPISelected(kpi) ? 'bg-gray-900 text-white' : 'bg-white hover:bg-gray-100 transition duration-300'}`}
              id={kpi.id}
            >
              <div class="justify-center">
                <div class={`${isLastElement(idx()) ? (kpi.value?.isPositive() ? 'text-green-500' : 'text-red-500') : ''} w-full uppercase`}>
                  <Stat customStyles={customKPIStyles} stat={kpi}/>
                </div>
              </div>
              <Show when={hovered() === kpi.id && kpi.breakdown}>{(breakdown: Accessor<Breakdown>) => (
                <div class="absolute left-0 top-full mt-3">
                  <BreakdownBox breakdown={breakdown()} position={{ top: 0, left: 0 }} />
                </div>
              )}</Show>
            </div>
            <Show when={!isLastElement(idx())}>
              <span class="text-gray-500 rotate-90 sm:hidden xl:block xl:rotate-0"><MiniArrowRight size={5} /></span>
            </Show>
          </>
        )}</For>
      </dl>
    </div>
  )
}

export default MainStats
