import { Trans } from '@mbarzda/solid-i18next'
import { Show, createEffect, createSignal, onMount, type Component } from 'solid-js'
import { MainModule } from '../../../features/main-module'
import { type Shop } from '../../../features/shop/domain/models/shop'
import { useShop } from '../../../shared/providers/shop.provider'
import styles from './FetchingShopDataBanner.module.scss'

const RefetchingShopDataBanner: Component = () => {
  const { selectedShop, setSelectedShop } = useShop()
  const getShop = MainModule.getShopComponent().provideGetShop()
  const [height, setHeight] = createSignal('auto')
  const [tempShop, setTempShop] = createSignal<Shop>()
  const [bannerVisible, setBannerVisible] = createSignal(false)

  let bannerRef: HTMLDivElement
  let closeTimeoutId: NodeJS.Timeout | undefined
  let pollingIntervalId: NodeJS.Timeout | undefined
  let prevIsUpdating = false

  const closeBanner = (): void => {
    setHeight(`${bannerRef.clientHeight}px`)
    setTimeout(() => {
      setHeight('0px')
    }, 200)
  }

  const handleTransitionEnd = (): void => {
    setBannerVisible(false)
    setSelectedShop(tempShop()!)
  }

  const fetchShop = async (): Promise<Shop | undefined> => {
    const shopId = selectedShop()?.id
    if (!shopId) return
    const shop = await getShop.execute(shopId)
    setTempShop(shop)
    if (!shop.isUpdating) {
      setTimeout(() => {
        closeBanner()
      }, 5000)
    }
    return shop
  }

  onMount(() => {
    setTempShop(selectedShop())
  })

  createEffect(() => {
    const shop = selectedShop()
    const isUpdating = shop?.isUpdating

    if (isUpdating) {
      if (closeTimeoutId) {
        clearTimeout(closeTimeoutId)
        closeTimeoutId = undefined
      }
      if (!bannerVisible()) setBannerVisible(true)
      setHeight('auto')

      if (!pollingIntervalId) {
        pollingIntervalId = setInterval(async () => {
          const updatedShop = await fetchShop()
          if (updatedShop && !updatedShop.isUpdating) {
            clearInterval(pollingIntervalId)
            pollingIntervalId = undefined
          }
        }, 2000)
      }
    } else {
      if (pollingIntervalId) {
        clearInterval(pollingIntervalId)
        pollingIntervalId = undefined
      }

      if (prevIsUpdating && bannerVisible() && !closeTimeoutId) {
        closeTimeoutId = setTimeout(() => {
          closeBanner()
          closeTimeoutId = undefined
        }, 5000)
      }
    }
    prevIsUpdating = !!isUpdating
  })

  const getText = (type: 'title' | 'description'): string =>
    tempShop()?.isUpdating
      ? `ls_shop_update_${type}`
      : `ls_shop_fetch_${type}_completed`

  const getProgressText = (): string =>
    tempShop()?.isUpdating
      ? 'ls_shop_update_history'
      : 'ls_shop_update_completed'

  return (
        <Show when={bannerVisible()}>
            <div
                ref={(el) => { bannerRef = el }}
                onTransitionEnd={handleTransitionEnd}
                style={{ height: height() }}
                class={`${height() === '0px' ? 'py-0' : 'py-2'} transition-all duration-500 overflow-hidden text-white relative flex gap-4 items-center bg-cyan-900 justify-between flex-col xl:flex-row px-8 xl:px-4`}
            >
                <img src="/images/search.svg" class="absolute hidden sm:block left-4 bottom-0 object-contain max-h-full" />
                <div class="flex flex-col xl:flex-row xl:items-center sm:pl-24 xl:pl-32 gap-4 w-full">
                    <div class="flex flex-col gap-2 justify-center w-full">
                        <div class="flex flex-col">
                            <p class="text-md m-0 font-semibold">
                                <Trans key={getText('title')} />
                            </p>
                            <p class="text-sm m-0">
                                <Trans key={getText('description')} />
                            </p>
                        </div>
                        <div class="flex flex-col sm:flex-row gap-2">
                            <div class={`${tempShop()?.isUpdating ? styles.progressBar : styles.progressBarDone} w-full flex items-center px-3`}>
                                <span class="text-xs text-white z-10 font-semibold">
                                    <Trans key={getProgressText()}/>
                                </span>
                                <div class={styles.progressValue}/>
                                {!tempShop()?.isUpdating && (
                                    <span class="ml-auto text-white text-lg">&#10003;</span>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Show>
  )
}

export default RefetchingShopDataBanner
