import { Trans } from '@mbarzda/solid-i18next'
import { A } from '@solidjs/router'
import { t } from 'i18next'
import { Show, type Component } from 'solid-js'
import { AppRoutes } from '../../shared/app-routes'

export interface AdsMissingCogsBannerProps {
  readonly numMissingCogs: number
  readonly translationKey?: string
}

const AdsMissingCogsBanner: Component<AdsMissingCogsBannerProps> = (props) => {
  return (
    <Show when={props.numMissingCogs}>{(missingCogs) => (
      <Show when={missingCogs() > 0}>
        <div class="text-white relative flex gap-4 items-center bg-red-600 justify-between min-h-[80px] flex-col xl:flex-row rounded-lg px-8 xl:px-4 py-4 xl:py-0">
          <img src='/images/bust.svg' class="absolute hidden sm:block sm:w-36 xl:w-[104px] sm:top-4 sm:left-2 xl:top-auto xl:bottom-0 xl:left-4"/>
          <div class="flex flex-col xl:flex-row xl:items-center sm:pl-[136px] xl:pl-[114px] gap-4 w-full">
            <div class="flex flex-col gap-1 justify-center w-full">
              <p class="text-xl m-0" innerHTML={t('ls_cogs_num_without_cogs_ads', { num: missingCogs() })} />
              <p class="text-sm m-0"><Trans key="ls_cogs_we_can"/></p>
            </div>
            <A href={AppRoutes.CogsManagement()} class="btn btn--white w-full xl:w-fit">
              <Trans key="ls_cogs_fix_now"/>
            </A>
          </div>
        </div>
      </Show>
    )}</Show>
  )
}

export default AdsMissingCogsBanner
