import { type Accessor, onCleanup, type Component } from 'solid-js'
import { useMeasureComponent } from '../../components/shared/functions/use-measure'
import { addComponentToDOM, calculateTooltipPosition, type Position, type Size } from './dom.functions'

interface UseTooltipProps {
  tooltipComponent?: Component<{ position?: Position }>
}

export const useTooltip = (props: UseTooltipProps): {
  showTooltip: (target: (MouseEvent | HTMLElement)) => void
  hideTooltip: () => void
} => {
  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  if (props.tooltipComponent) {
    tooltipSize = useMeasureComponent(props.tooltipComponent({}))
  }

  const showTooltip = (target: MouseEvent | HTMLElement): void => {
    if (!tooltipSize?.()) return

    const hoveredEl = target instanceof MouseEvent ? target.currentTarget as HTMLElement : target
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() => props.tooltipComponent?.({ position }))
  }

  const hideTooltip = (): void => {
    if (props.tooltipComponent) removeTooltip()
  }

  onCleanup(hideTooltip)

  return { showTooltip, hideTooltip }
}
