import { type Component, createEffect, createSignal, For, onCleanup, onMount, Show } from 'solid-js'
import { MainModule } from '../../features/main-module'
import TablePagination from '../shared/components/TablePagination'
import SearchBar from '../shared/components/SearchBar'
import { Trans } from '@mbarzda/solid-i18next'
import AdsTableRow from './AdsTableRow'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { useShop } from '../../shared/providers/shop.provider'
import { usePeriods } from '../../shared/providers/period.provider'
import { KPIType } from '../../features/analytics/domain/models/kpi'
import { useError } from '../../shared/providers/error.provider'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import { useLocation } from '@solidjs/router'
import { type Ad } from '../../features/product/domain/models/ad'
import RectangleGroupSolid from '../../assets/heroicons/RectangleGroupSolid'
import NoExtensionWarning from './NoExtensionWarning'
import { type AdsSyncStatus } from '../../features/product/domain/models/ads-totals'
import NeedAdsSyncBanner from './NeedAdsSyncBanner'
import { FetchStatus } from '../../features/shop/domain/models/shop'
import AdsMissingCogsBanner from './AdsMissingCogsBanner'

const Ads: Component = () => {
  const DEFAULT_PAGE_SIZE = 10
  const [orderBy, setOrderBy] = createSignal<string | undefined>(undefined)
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [isExtensionFound, setIsExtensionFound] = createSignal<boolean | undefined>(undefined)
  const [ads, setAds] = createSignal<PaginationOffsetLimit<Ad>>()
  const [page, setPage] = createSignal(0)
  const [numMissingCogs, setNumMissingCogs] = createSignal<number>(0)
  const [syncStatus, setSyncStatus] = createSignal<AdsSyncStatus | undefined>()
  const [extensionId, setExtensionId] = createSignal<string>()

  const { selectedShop } = useShop()
  const { periods } = usePeriods()
  const { setError } = useError()
  const location = useLocation()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'ad' },
    { id: 'status' },
    { id: 'ad_spent', withHint: true, sortable: true },
    { id: KPIType.Tacos, withHint: true, sortable: true },
    /* { id: KPIType.BreakEvenRoas, withHint: true, sortable: true }, */
    { id: KPIType.Roas, withHint: true, sortable: true },
    { id: 'sales.total', withHint: true, sortable: true },
    { id: 'orders.total', withHint: true, sortable: true }
  ]

  const isExtensionInstalled = MainModule.getExtensionComponent().provideGetIsExtensionInstalled()
  const startSync = MainModule.getExtensionComponent().provideSyncAds()
  const getAds = MainModule.getProductComponent().provideGetAds()
  const getAdsTotals = MainModule.getProductComponent().provideGetAdsTotals()

  onMount(() => {
    void checkChromeExtension().then((isInstalled) => {
      if (isInstalled) {
        window.addEventListener('extension-sync-started', handleExtensionButtonClick)
      }
    })

    onCleanup(() => {
      window.removeEventListener('extension-sync-started', handleExtensionButtonClick)
    })
  })

  const checkChromeExtension = async (): Promise<boolean> => {
    const ids: string[] = import.meta.env.VITE_EXTENSION_ID.split(',')
    for (const id of ids) {
      const isInstalled = await isExtensionInstalled.execute(id)
      console.info(`Extension ${id} is installed: ${isInstalled}`)
      if (isInstalled) {
        setExtensionId(id)
        setIsExtensionFound(true)
        return true
      }
    }

    setIsExtensionFound(false)
    return false
  }

  const refreshTable = (page: number = 1): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    setIsLoading(true)

    const offset = (page - 1) * DEFAULT_PAGE_SIZE
    const getAdsRequest = getAds.execute(
      shop.id,
      periods(),
      offset,
      DEFAULT_PAGE_SIZE,
      orderBy(),
      searchTerm()
    )
      .then((ads) => {
        setAds(ads)
      })

    const getTotalsRequest = getAdsTotals.execute(
      shop.id,
      periods()
    )
      .then((totals) => {
        // setNumMissingCogs(totals.missing_cogs_ads)
        setNumMissingCogs(0)
        setSyncStatus(totals.status)
      })

    void executeWithUrlScope(
      async () => await Promise.all([
        getAdsRequest,
        getTotalsRequest
      ]), location
    )
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const showNoResults = (): boolean => {
    return ads()?.values.length === 0
  }

  const isFiltering = (): boolean => {
    return !!searchTerm()
  }

  createEffect(() => {
    orderBy()
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    refreshTable(page())
  })

  const getTableStatus = (): TableStatus => {
    const status = getGenericTableStatus(isLoading(), showNoResults(), isFiltering(), ads()?.values)
    return status === TableStatus.NoData ? TableStatus.Custom : status
  }

  const handleExtensionButtonClick = async (): Promise<void> => {
    console.info('funcs')
    if (extensionId()) {
      await startSync.execute(extensionId())
      setSyncStatus('in_progress')
    }
  }

  return (
    <>
      <Show when={isExtensionFound() === false}>
        <NoExtensionWarning />
      </Show>
      <Show when={isExtensionFound()}>
        <div class="flex flex-col gap-4">
          <Show when={/* numMissingCogs() > 0 || */ syncStatus() === 'pending'}>
            <div class="mb-6 flex flex-col gap-4">
              <Show when={numMissingCogs() > 0}>
                <AdsMissingCogsBanner numMissingCogs={numMissingCogs()} />
              </Show>
              <Show when={syncStatus() === 'pending'}>
                <NeedAdsSyncBanner />
              </Show>
            </div>
          </Show>
          <h1 class="page-title"><RectangleGroupSolid /><Trans key="ls_ads_title"/></h1>
          <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title" />
          <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
            <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
                <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns} status={getTableStatus} tableId="ads" isCustomNoDataMsg>
                  <For each={ads()?.values}>{(ad) => (
                    <AdsTableRow ad={ad} isLoading={isLoading}/>
                  )}</For>
                  <Show when={ads()?.values.length === 0}>
                    <td class="h-60">
                      <div class="absolute left-0 w-full top-0 bottom-0 flex items-center justify-center">
                        <div class="flex flex-col items-center justify-center gap-1 p-10">
                          <img class="h-28" src='/images/no-results.png' alt="no-results"/>
                          <Show when={ selectedShop()?.fullFetch === FetchStatus.Completed }>
                            <p class="text-base font-semibold"><Trans key={'ls_ads_no_data_title'}/></p>
                            <div id="extension-link-container" />
                          </Show>
                          <Show when={ selectedShop()?.fullFetch !== FetchStatus.Completed }>
                            <p class="text-base font-semibold"><Trans key={'ls_ads_no_data_no_shop_title'}/></p>
                            <Trans key="ls_ads_no_data_no_shop_description" />
                          </Show>
                        </div>
                      </div>
                    </td>
                  </Show>
                </Table>
              </div>
              <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
                <TablePagination totalCount={ads()?.size ?? 0} page={page} setPage={setPage}
                                 pageSize={DEFAULT_PAGE_SIZE}/>
              </Show>
            </div>
          </div>
        </div>
      </Show>
    </>
  )
}

export default Ads
