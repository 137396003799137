export class CogsUpdateReportEntity {
  constructor(
    public readonly success: number,
    public readonly products_not_found: number,
    public readonly products_pod: number,
    public readonly total: number
  ) {
  }
}

export class CogsUpdateStatusEntity {
  constructor(
    public readonly id: string,
    public readonly status: 'pending' | 'completed',
    public readonly filename: string,
    public readonly report: CogsUpdateReportEntity | undefined
  ) {
  }
}
