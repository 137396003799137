import { type Component, Match, Switch } from 'solid-js'
import Printful from '../../assets/images/printful.svg'
import Printify from '../../assets/images/printify.svg'
import { type CogsAttribution } from '../../features/product/domain/models/cogs-management'

export interface CogsManagementImageCellProps {
  readonly imgUrl: string
  readonly attributions: CogsAttribution
}

const CogsManagementImageCell: Component<CogsManagementImageCellProps> = (props) => {
  return (
    <div class="relative h-[52px] w-[52px]">
      <img class="rounded-lg" src={props.imgUrl} alt=""/>
      <Switch>
        <Match when={props.attributions.printful > 0 && props.attributions.printify > 0}>
          <div class="absolute bottom-0.5 right-1 text-white bg-gray-600 flex items-center justify-center h-5 w-5 rounded-sm">
            <span class="text-[8px] text-white font-semibold" style={{ 'letter-spacing': '-0.5px' }}>POD</span>
          </div>
        </Match>
        <Match when={props.attributions.printful > 0}>
          <div class="absolute bottom-0.5 right-1 rounded-sm">
            <img src={Printful} alt="Printful"/>
          </div>
        </Match>
        <Match when={props.attributions.printify > 0}>
          <div class="absolute bottom-0.5 right-1 rounded-sm">
            <img src={Printify} alt="Printify"/>
          </div>
        </Match>
      </Switch>
    </div>
  )
}

export default CogsManagementImageCell
