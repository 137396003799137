import { type Component, createSignal, For, onCleanup, onMount, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { MainModule } from '../../features/main-module'
import {
  type CogsUpdateReport,
  type CogsUpdateStatus
} from '../../features/product/domain/models/cogs-update-status'
import { useShop } from '../../shared/providers/shop.provider'
import MiniLoading from '../shared/components/MiniLoading'
import { useTracking } from '../../shared/providers/tracking.provider'
import CheckCircleSolid from '../../assets/heroicons/CheckCircleSolid'
import CogsUpdateReportDialog from './CogsUpdateReportDialog'
import { createStore } from 'solid-js/store'

export interface ProcessingCogsBannerProps {
  readonly refresh: (action: () => void) => void
  readonly onFinishPulling: () => void
}

const ProcessingCogsBanner: Component<ProcessingCogsBannerProps> = (props) => {
  const [status, setStatus] = createStore<CogsUpdateStatus[]>([])
  const [areTherePendingUpdates, setAreTherePendingUpdates] = createSignal(false)
  const [shownReport, setShownReport] = createSignal<CogsUpdateReport>()
  const { selectedShopId } = useShop()
  const { trackEvent } = useTracking()

  let interval: number | NodeJS.Timeout | undefined
  const cancelInterval = (): void => {
    if (interval) clearInterval(interval)
    interval = undefined
  }

  onMount(() => {
    startPullingCheck()
  })

  const getCogsUpdateStatus = MainModule.getProductComponent().provideGetCogsUpdateStatus()
  const markCogsUpdateAsViewed = MainModule.getProductComponent().provideMarkCogsUpdateAsViewed()

  const startPullingCheck = (): void => {
    const checkTaskStatus = (): void => {
      getCogsUpdateStatus.execute(selectedShopId()!).then((status: CogsUpdateStatus[]) => {
        const sortedStatus = status.sort((a) => a.status === 'completed' ? -1 : 1)
        setStatus(sortedStatus)
        setAreTherePendingUpdates(status.some((s) => s.status === 'pending'))
        if (!areTherePendingUpdates() && interval) {
          props.onFinishPulling()
          trackEvent('Cogs Bulk Update', { shopId: selectedShopId(), status: 'completed' })
          cancelInterval()
        }
      }).catch(() => {
        if (interval) cancelInterval()
      })
    }

    if (interval) cancelInterval()
    interval = setInterval(() => {
      checkTaskStatus()
    }, 3000)

    checkTaskStatus()
  }

  props.refresh(startPullingCheck)

  onCleanup(() => { if (interval) cancelInterval() })

  const onViewResults = async (status: CogsUpdateStatus): Promise<void> => {
    setShownReport(status.report)
    trackEvent('Cogs Bulk Update', { shopId: selectedShopId(), status: 'viewed' })
    await markCogsUpdateAsViewed.execute(selectedShopId()!, status.id)
    if (!interval) {
      startPullingCheck()
    }
  }

  return (
    <>
    <Show when={status.length > 0}>
      <div class="rounded-lg bg-white flex flex-col divide-y shadow-sm">
        <For each={status}>{(status) => (
          <>
          <Show when={status.status === 'completed'}>
            <div class="flex justify-between gap-3 p-4">
              <div class="flex text-sm font-semibold gap-2">
                <span class="text-green-500"><CheckCircleSolid/></span>
                <span class="flex flex-wrap mt-[1px] gap-1">
                  <span><Trans key={'ls_cogs_update_processing_completed'} options={{ filename: status.filename }}/></span>
                  <span><Trans key={'ls_cogs_update_processing_completed2'}/></span>
                </span>
              </div>
              <button class="text-japanese-600 font-semibold text-sm whitespace-nowrap"
                      onClick={() => { void onViewResults(status) }}>
                <Trans key="ls_cogs_update_view_results"/>
              </button>
            </div>
          </Show>
          <Show when={status.status === 'pending'}>
            <div class="flex flex-col gap-2 p-4">
              <div class="flex justify-between gap-3 items-start">
                <div class="flex text-sm font-semibold gap-2">
                  <div class="flex w-6 justify-center mt-[1px]"><MiniLoading/></div>
                  <span class="flex flex-wrap">
                    <span>
                      <Trans key={'ls_cogs_update_processing_pending'} options={{ filename: status.filename }}/>
                    </span>
                  </span>
                </div>
              </div>
              <div class="flex text-sm text-gray-500">
                <div class="hidden sm:flex flex-wrap gap-1">
                  <span><Trans key={'ls_cogs_update_explanation1'}/></span><span><Trans key={'ls_cogs_update_explanation2'}/></span>
                </div>
                <div class="sm:hidden"><Trans key={'ls_cogs_update_explanation1'}/> <Trans key={'ls_cogs_update_explanation2'}/></div>
              </div>
            </div>
          </Show>
          </>
        )}</For>
      </div>
    </Show>
    <Show when={shownReport()}>{(report) => (
      <CogsUpdateReportDialog report={report()} close={() => { setShownReport(undefined) }}/>
    )}</Show>
    </>
  )
}

export default ProcessingCogsBanner
