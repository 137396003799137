import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class GetCogsSummaryQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly startTime?: number,
    private readonly endTime?: number
  ) {
    super(ApiEndpoint.CogsManagementSummary)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      ...this.startTime && { from_date: this.startTime },
      ...this.endTime && { to_date: this.endTime }
    }
  }
}
