import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type TimeseriesResolution } from '../../../../components/shared/analytics.vm'
import { correctDateToCurrentOffset } from '../../../../shared/helpers/date.functions'
import { type PeriodTime } from '../../../shared/period-state'
import { GetAnalyticsTimeseriesQuery } from '../../data/queries/get-analytics-timeseries.query'
import { type Timeseries } from '../models/timeseries'

export class GetAnalyticsTimeseriesInteractor {
  constructor(
    private readonly getAnalytics: GetInteractor<Timeseries>
  ) {
  }

  public async execute (shopId: number, period: PeriodTime, resolution: TimeseriesResolution): Promise<Timeseries> {
    const startOfPeriod = correctDateToCurrentOffset(period.from * 1000) / 1000
    const endOfPeriod = correctDateToCurrentOffset(period.to * 1000) / 1000
    return await this.getAnalytics.execute(new GetAnalyticsTimeseriesQuery(shopId, startOfPeriod, endOfPeriod, resolution))
  }
}
