import { useNavigate } from '@solidjs/router'
import { Show, createSignal, onMount, type Component } from 'solid-js'
import { MainModule } from '../../features/main-module'
import { isSubscription } from '../../features/payment/domain/models/subscription'
import { useUser } from '../../shared/providers/user-provider'
import { AppRoutes } from '../../shared/app-routes'
import ProcessingSubscriptionUpdateDialog from '../payment/ProcessingSubscriptionUpdateDialog'
import LifetimeSectionCard from './LifetimeSectionCard'
import ProfileSectionCard from './ProfileSectionCard'
import SubscribedSectionCard from './SubscribedSectionCard'
import UnsubscribedSectionCard from './UnsubscribedSectionCard'
import UpgradePlanCard from './UpgradePlanCard'

const AccountSettings: Component = () => {
  const { userPurchases, currentSubscription, hasAccess, setUserPurchases } = useUser()
  const navigate = useNavigate()
  const [isUpdatingSubscription, setIsUpdatingSubscription] = createSignal<string>()

  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()

  onMount(async(): Promise<void> => {
    await refreshLocalSubscription()
  })

  const onSubscriptionUpdated = async (): Promise<void> => {
    await refreshLocalSubscription()
    setIsUpdatingSubscription(undefined)
    navigate(AppRoutes.Dashboard(), { replace: true })
  }

  const refreshLocalSubscription = async(): Promise<void> => {
    const purchases = await getPurchases.execute()
    setUserPurchases(purchases)
  }

  return (
    <div class="flex flex-wrap justify-center gap-8 w-full">
      <Show when={isUpdatingSubscription()}>{(subId) => (
        <ProcessingSubscriptionUpdateDialog onSubscriptionReady={onSubscriptionUpdated} newSubscriptionId={subId()}/>
      )}</Show>
      <Show when={hasAccess()} fallback={<UnsubscribedSectionCard />}>
        <ProfileSectionCard />
        <div class="flex flex-col gap-4 flex-grow items-center max-w-[600px] h-fit">
          <Show when={isSubscription(currentSubscription())} fallback={
            <LifetimeSectionCard />
          }>
            <SubscribedSectionCard onUpdateSubscription={setIsUpdatingSubscription} />
          </Show>
          <Show when={!userPurchases()?.isPro}>
            <UpgradePlanCard />
          </Show>
        </div>
      </Show>
    </div>
  )
}

export default AccountSettings
