import {
  type DeleteDataSource,
  type GetDataSource,
  type PutDataSource
} from './data-source/data-source'
import { type Operation } from './operation/operation'
import { type Query } from './query/query'
import { type Repository } from './repository'
import type {ApiRequestService} from "./data-source/api-request.service";
import {provideDefaultNetworkDataSource} from "./data-source/network.data-source";

export class SingleVoidDataSourceRepository implements Repository<void> {
  constructor (
    private readonly getDataSource: GetDataSource<void>,
    private readonly putDataSource: PutDataSource<void>,
    private readonly deleteDataSource: DeleteDataSource,
  ) {
  }

  public async get(query: Query, _operation: Operation): Promise<void> {
    return await this.getDataSource.get(query)
  }

  public async put(value: void | undefined, query: Query, _operation: Operation): Promise<void> {
    return await this.putDataSource.put(value, query)
  }

  public async delete(query: Query, _operation: Operation): Promise<void> {
    await this.deleteDataSource.delete(query)
  }
}

export function provideVoidNetworkDataSourceRepository(requestService: ApiRequestService): Repository<void> {
  const dataSource = provideDefaultNetworkDataSource<void>(requestService)
  return new SingleVoidDataSourceRepository(dataSource, dataSource, dataSource)
}

