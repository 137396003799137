import { createEffect, createSignal, For, Show } from 'solid-js'
import type { JSX } from 'solid-js'
import { clickOutside } from '../../../shared/directives/click-outside'
import MiniChevronDown from '../../../assets/heroicons/MiniChevronDown'
import { Trans } from '@mbarzda/solid-i18next'

export interface MultiSelectButtonProps<T> {
  readonly options: Record<string, T>
  readonly placeholder?: string
  readonly value: T[] | undefined
  readonly setSelectedOptions: (filters: T[]) => void
  readonly key?: string
}

const MultiSelectButton = <T extends string | number>(props: MultiSelectButtonProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = createSignal<boolean>(false)
  const [selectedValues, setSelectedValues] = createSignal<T[]>(props.value ?? [])

  const numOptionsSelected = (): number => selectedValues().length

  createEffect(() => {
    setSelectedValues(props.value ?? [])
  })

  const handleCheckboxChange = (value: T, event: Event): void => {
    const target = event.target as HTMLInputElement
    setSelectedValues((prev) => {
      if (target.checked) {
        return [...prev, value]
      }
      return prev.filter((val) => val !== value)
    })
    props.setSelectedOptions(selectedValues())
  }

  return (
    <div class="relative w-full" ref={el => {
      clickOutside(el, () => setIsOpen(false))
    }}>
      <div class={`${numOptionsSelected() ? 'btn--primary text-white' : 'btn--white text-gray-900'} btn w-full justify-between font-normal`}
           onClick={() => setIsOpen(open => !open)}>
        <Trans key={props.placeholder ?? props.key ?? ''}/>
        <div class="flex gap-2 items-center">
          <div class="min-w-2">{numOptionsSelected() || ''}</div>
          <MiniChevronDown/>
        </div>
      </div>
      <Show when={isOpen()}>
        <ul class="left-0 w-full absolute flex flex-col gap-4 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option">
          <For each={Object.entries(props.options)}>{([key, value]) => (
            <li class="text-gray-900 text-sm relative cursor-pointer select-none px-4"
                role="option">
              <label onChange={(ev) => { handleCheckboxChange(value, ev) }}
                     for={`option-${key}`} class="flex items-center gap-2 cursor-pointer">
                <input id={`option-${key}`} checked={selectedValues().includes(value)}
                       type="checkbox"
                       class="h-4 w-4 cursor-pointer !rounded !border-gray-300 focus:!ring-transparent !text-japanese-600 checked:!border-0"/>
                <span class="font-normal block truncate"><Trans key={`${props.key ? props.key + '_' : ''}${key}`} /></span>
              </label>
            </li>
          )}</For>
        </ul>
      </Show>
    </div>
  )
}

export default MultiSelectButton
