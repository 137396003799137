import { Trans } from '@mbarzda/solid-i18next'
import { type Component } from 'solid-js'
import issue from '../../assets/images/issue.png'
import DialogActions from '../shared/components/Dialog/DialogActions'

export interface PastDueBlockCardProps {
  readonly updatePaymentUrl: string
}

const PastDueBlockDialog: Component<PastDueBlockCardProps> = (props) => {
  const openIntercom = (): void => {
    window.Intercom('show')
  }

  return (
    <div class="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
      <div
        class="relative transform bg-white text-left shadow-xl transition-all sm:my-8 lg:w-full m-3 max-w-[624px] rounded-md"
      >
        <div class="flex flex-col gap-4 p-8 text-center">
          <div class="flex justify-center">
            <img src={issue} class="h-24" alt="error"/>
          </div>
          <div class="flex flex-col gap-2 items-center justify-center">
            <span class="text-gray-900 font-bold"><Trans key="ls_subscription_due_title"/></span>
            <div class="flex flex-col gap-4 items-center justify-center">
              <span class="text-gray-500 text-sm"><Trans key="ls_subscription_due_description1"/></span>
                <span class="text-gray-500 text-sm"><Trans key="ls_subscription_due_description2"/></span>
              </div>
            </div>
          </div>
          <DialogActions>
            <button
              onClick={openIntercom}
              class="btn btn--white">
              <Trans key="ls_shared_contact_support"/>
            </button>
            <a href={props.updatePaymentUrl} class="btn btn--primary" target="_blank">
              <Trans key="ls_subscription_manage"/>
            </a>
          </DialogActions>
      </div>
    </div>
  )
}

export default PastDueBlockDialog
