import {
  createSignal,
  onMount,
  Show,
  type Component,
  onCleanup
} from 'solid-js'
import { clickOutside } from '../../shared/directives/click-outside'
import MiniEtsy from '../../assets/heroicons/MiniEtsy'
import { Trans } from '@mbarzda/solid-i18next'
import MiniChevronDown from '../../assets/heroicons/MiniChevronDown'
import NoListing from '../../assets/images/no-listing-sm.svg'
import {
  findScrollableParent,
  type Position
} from '../../shared/helpers/dom.functions'
import ListingListTooltip from '../products/ListingListTooltip'
import RectangleStackSolid from '../../assets/heroicons/RectangleStackSolid'
import { type Product } from '../../features/product/domain/models/product'
import { useTooltip } from '../../shared/helpers/useTooltip'

export interface ImageListingsCellProps {
  readonly product: Product
  readonly numListings: number
}

const ImageListingsCell: Component<ImageListingsCellProps> = (props) => {
  const [isTooltipOpen, setIsTooltipOpen] = createSignal(false)

  let buttonRef: HTMLButtonElement | null = null

  const tooltipComponent = props.numListings > 1
    ? (positionProps: { position?: Position }) => <ListingListTooltip listing={props.product.listing} numListings={props.numListings} {...positionProps} />
    : undefined

  const { showTooltip, hideTooltip } = useTooltip({ tooltipComponent })

  onMount(() => {
    if (buttonRef) {
      const scrollParent = findScrollableParent(buttonRef)
      if (scrollParent) {
        scrollParent.addEventListener('scroll', hideTooltip)
        window.addEventListener('resize', hideTooltip)

        onCleanup(() => {
          scrollParent.removeEventListener('scroll', hideTooltip)
          window.removeEventListener('resize', hideTooltip)
        })
      }
    }
  })

  const onListingsClick = (): void => {
    if (isTooltipOpen()) {
      hideTooltip()
    } else {
      showTooltip(buttonRef!)
    }

    setIsTooltipOpen((value) => !value)
  }

  const listingImage = props.product.listing.isDeleted() ? NoListing : props.product.listing.imgUrls.sm
  const listingTitle = props.product.listing.isDeleted() ? 'ls_listing_deleted' : props.product.listing.title

  return (
    <div class="flex gap-6">
      <div class="relative">
        <img class="h-[52px] min-w-[52px] rounded-lg" src={listingImage} alt={props.product.variantName} />
        <Show when={props.numListings > 1}>
          <div class="absolute bottom-0.5 right-1 text-white bg-gray-900 p-0.5 rounded-sm">
            <RectangleStackSolid size={4} />
          </div>
        </Show>
      </div>
      <div class="flex flex-col justify-center">
        <div class="line-clamp-1 mb-1"><Trans key={listingTitle} /></div>
        <div class="text-xs text-blue-500 relative" ref={(el) => {
          clickOutside(el, () => {
            setIsTooltipOpen(false)
            hideTooltip()
          })
        }}>
          <Show when={props.numListings === 1}>
            <a href={props.product.listing.url} target="_blank"
               class="text-gray-500 flex items-center gap-1">
              <MiniEtsy size={3} /><Trans key="ls_shared_view_on_etsy" />
            </a>
          </Show>
          <Show when={props.numListings > 1}>
            <button
              ref={(el) => (buttonRef = el)}
              onClick={onListingsClick}
              class="flex items-center gap-1">
              <MiniChevronDown size={3} />{props.numListings} <Trans key="ls_cogs_management_listings" />
            </button>
          </Show>
        </div>
      </div>
    </div>
  )
}

export default ImageListingsCell
