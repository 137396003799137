import { type Component, Show } from 'solid-js'
import { NumericalDataType } from '../../shared/models/numerical-data-type'
import { type CogsManagement } from '../../features/product/domain/models/cogs-management'
import CogsManagementImageCell from './CogsManagementImageCell'
import ValueCell from '../shared/components/ValueCell'
import CogsManagementSkuCell from './CogsManagementSkuCell'
import ProductListingCell from '../products/ProductListingCell'
import CheckboxCell from '../shared/components/CheckboxCell'
import MissingCogsCell from './MissingCogsCell'
import OrdersBreakdownTooltip from './OrdersBreakdownTooltip'

export interface CogsManagementTableRowProps {
  readonly cogsManagement: CogsManagement
  readonly isSelected?: boolean
  readonly onSelectionChange: (isSelected: boolean) => void
  readonly onEditSingleCogs: () => void
  readonly isEditable: boolean
  readonly isMultipleSelected: boolean
}

const CogsManagementTableRow: Component<CogsManagementTableRowProps> = (props) => {
  const cogs = props.cogsManagement

  return (
    <tr>
      <td class="py-3.5 px-3 max-w-[32px]">
        <Show when={!cogs.isPod}>
          <CheckboxCell isSelected={props.isSelected} onValueChange={props.onSelectionChange}/>
        </Show>
      </td>
      <td class="py-3.5 px-3 min-w-[88px]">
        <CogsManagementImageCell imgUrl={cogs.product.listing.imgUrls.sm} attributions={cogs.attributions}/>
      </td>
      <td class="tableCell min-w-[220px] whitespace-nowrap">
        <CogsManagementSkuCell product={cogs.product} numListings={cogs.numListings}/>
      </td>
      <td class="tableCell min-w-[260px]">
        <ProductListingCell product={cogs.product} numListings={cogs.numListings}/>
      </td>
      <td class="tableCell min-w-[120px] pl-6">
        <ValueCell
          value={cogs.numOrders}
          type={NumericalDataType.Units}
          tooltip={(positionProps) => <OrdersBreakdownTooltip {...positionProps } attribution={cogs.attributions} />}
        />
      </td>
      <td class="tableCell min-w-[120px]">
        <Show when={cogs.isPod}>
          <ValueCell value={cogs.lastAttributedCogs} type={NumericalDataType.Currency}/>
        </Show>
        <Show when={!cogs.isPod}>
          <ValueCell value={cogs.product.cogs?.amount} type={NumericalDataType.Currency}/>
        </Show>
      </td>
      <td class="tableCell min-w-[140px]">
        <MissingCogsCell
          cogs={cogs}
          onEditSingleCogs={props.onEditSingleCogs}
          isDisabled={!props.isEditable}
          hideActions={props.isMultipleSelected}
        />
      </td>
    </tr>
  )
}

export default CogsManagementTableRow
