import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type ProductEntity, type SKUProductReportEntity } from '../../data/entities/product.entity'
import { KPIEntityToKPIMapper } from '../../../analytics/domain/mappers/kpi.mappers'
import { KPIType } from '../../../analytics/domain/models/kpi'
import { type Product, SKUProductReport } from '../models/product'
import {
  getNetSalesBreakdownKpis,
  getOpExpensesBreakdownKpis
} from '../../../../components/shared/functions/kpi.functions'
import { CogsTypeEntity } from '../../data/entities/cogs.entity'
import { CogsType } from '../models/cogs'

export class SKUProductReportEntityToSKUProductReportMapper implements Mapper<SKUProductReportEntity, SKUProductReport> {
  constructor(
    private readonly productMapper: Mapper<ProductEntity, Product>
  ) {
  }

  public map(from: SKUProductReportEntity): SKUProductReport {
    const KPIs = from.data_points
    const firstProduct = from.products[0]
    const UnitsSold = KPIEntityToKPIMapper(KPIs[KPIType.UnitsSold], KPIType.UnitsSold)
    const isPrintful = firstProduct.cogs_type === CogsTypeEntity.Printful
    const isPrintify = firstProduct.cogs_type === CogsTypeEntity.Printify
    const cogsType = isPrintful
      ? CogsType.Printful
      : isPrintify
        ? CogsType.Printify
        : CogsType.None

    return new SKUProductReport(
      firstProduct.sku,
      this.productMapper.map(firstProduct),
      cogsType,
      from.missing_cogs,
      KPIEntityToKPIMapper(KPIs[KPIType.UnitsSold], KPIType.UnitsSold),
      KPIEntityToKPIMapper(KPIs[KPIType.NetSales], KPIType.NetSales, getNetSalesBreakdownKpis(KPIs), UnitsSold),
      KPIEntityToKPIMapper(KPIs[KPIType.Cogs], KPIType.Cogs),
      KPIEntityToKPIMapper(KPIs[KPIType.OperatingExpenses], KPIType.OperatingExpenses, getOpExpensesBreakdownKpis(KPIs), UnitsSold),
      KPIEntityToKPIMapper(KPIs[KPIType.OperatingProfit], KPIType.OperatingProfit),
      KPIEntityToKPIMapper(KPIs[KPIType.Profit], KPIType.Profit),
      KPIEntityToKPIMapper(KPIs[KPIType.Roi], KPIType.Roi)
    )
  }
}
