import { type Component, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'
import MiniExclamationTriangle from '../../assets/heroicons/MiniExclamationTriangle'
import InfoTooltip from '../shared/components/InfoTooltip'
import { AppRoutes } from '../../shared/app-routes'
import { A } from '@solidjs/router'

export interface ValueCellProps {
  readonly value: number
  readonly missingCogs?: boolean
}

const BeroasCell: Component<ValueCellProps> = (props) => {
  const { numberFormatter } = useNumberFormatter()

  return (
    <div class="flex items-center gap-2 text-sm">
      <Show when={props.missingCogs}>
        <span class="relative group">
          <A href={AppRoutes.CogsManagement()}>
            <span class="text-red-500"><MiniExclamationTriangle/></span>
          </A>
          <InfoTooltip translationKey="ls_ads_breakdown_cogs_error" class="hidden absolute top-10 left-0 group-hover:block !w-[270px]" />
        </span>
      </Show>
      <Show when={!props.missingCogs} fallback={
        <span class="text-gray-500"><Trans key="ls_generic_n_a"/></span>
      }>
        <span class="text-gray-900">
          {numberFormatter(props.value)}
        </span>
      </Show>
    </div>
  )
}

export default BeroasCell
