import {
  type Accessor,
  type Component,
  For,
  type JSX,
  Match,
  type Setter, Show,
  Switch
} from 'solid-js'
import TableHeaderCell from './TableHeaderCell'
import TableNoResults from './TableNoResults'
import LottieAnimation from './LottieAnimation'
import logoAnimation from '../../../assets/animations/logo-animated.json'
import CheckboxCell from './CheckboxCell'

export interface TableColumn {
  readonly id: string
  readonly withHint?: true
  readonly sortable?: true
}

export enum TableStatus {
  Init = 'init',
  Loaded = 'loaded',
  Loading = 'loading',
  NoData = 'no_data',
  NoSearchResults = 'no_search_results',
  Custom = 'custom'
}

export interface TableProps {
  readonly status: () => TableStatus
  readonly children?: JSX.Element | string
  readonly orderBy?: Accessor<string | undefined>
  readonly setOrderBy?: Setter<string | undefined>
  readonly columns: TableColumn[]
  readonly tableId?: string
  readonly isCustomNoDataMsg?: true
  readonly onSelectAll?: (value: boolean) => void
  readonly setSelectorRef?: (el: HTMLInputElement) => void
}

const Table: Component<TableProps> = (props) => {
  const setOrderBy = (id: string): void => {
    if (!props.setOrderBy || !props.orderBy) {
      return
    }

    const currentOrderBy = props.orderBy()
    if (currentOrderBy) {
      const [currentId, status] = currentOrderBy.split(':')
      if (currentId === id) {
        if (status === 'desc') {
          props.setOrderBy(`${id}:asc`)
          return
        }
        props.setOrderBy(undefined)
        return
      }
    }

    props.setOrderBy(`${id}:desc`)
  }

  const getSortStatus = (id: string): 'asc' | 'desc' | undefined => {
    if (!props.orderBy?.()) {
      return undefined
    }

    const [activeId, status] = props.orderBy()!.split(':')
    if (activeId === id) {
      return status as 'asc' | 'desc'
    }

    return undefined
  }

  const onSetOrder = (): (id: string) => void => {
    if ([TableStatus.Loading, TableStatus.Init].includes(props.status())) {
      return () => {}
    }
    return setOrderBy
  }

  return (
    <table class="table-fixed min-w-full divide-y divide-gray-300 bg-white">
      <thead>
        <tr class="text-left text-sm font-semibold">
          <For each={props.columns}>{(col) => (
            <Switch>
              <Match when={col.id === 'select' && props.onSelectAll}>
                <th scope="col" class="py-3.5 px-3">
                  <CheckboxCell onValueChange={(value) => props.onSelectAll?.(value)} setSelectorRef={props.setSelectorRef}/>
                </th>
              </Match>
              <Match when={col.id === 'image'}>
                <th scope="col" class={`${props.onSelectAll ? 'px-3' : 'pl-4 pr-3 sm:pl-6'} py-3.5`}/>
              </Match>
              <Match when={col.id !== 'image' && col.id !== 'select'}>
                <th scope="col" class="px-3 py-3.5">
                  <TableHeaderCell id={col.id} status={getSortStatus(col.id)}
                                   setOrderBy={onSetOrder()} withHint={col.withHint}
                                   sortable={col.sortable}/>
                </th>
              </Match>
            </Switch>
          )}</For>
        </tr>
      </thead>
      <tbody class="relative divide-y divide-gray-200 text-gray-900">
      <Switch>
        <Match when={[TableStatus.Loaded, TableStatus.Loading, TableStatus.Custom].includes(props.status())}>
            {props.children}
            <Show when={props.status() === TableStatus.Loading}>
              <div class="opacity-70 absolute top-0 left-0 w-full bottom-0 bg-white" />
            </Show>
          </Match>
          <Match when={props.status() === TableStatus.Init}>
            <td colSpan={props.columns.length} class="h-60" />
          </Match>
          <Match when={props.status() === TableStatus.NoSearchResults}>
            <td class="h-60">
              <div class="absolute left-0 w-full top-0 bottom-0 flex items-center justify-center">
                <TableNoResults type="no_search_results" id={props.tableId}/>
              </div>
            </td>
          </Match>
          <Match when={props.status() === TableStatus.NoData}>
            <td class="h-60">
              <div class="absolute left-0 w-full top-0 bottom-0 flex items-center justify-center">
                <TableNoResults type="no_data" id={props.isCustomNoDataMsg ? props.tableId : undefined}/>
              </div>
            </td>
          </Match>
        </Switch>
      </tbody>
      <Show when={[TableStatus.Loading, TableStatus.Init].includes(props.status())}>
        <div class="absolute top-1/2 left-1/2 border-none">
          <LottieAnimation animationData={logoAnimation} width="40px"/>
        </div>
      </Show>
    </table>
  )
}

export default Table
