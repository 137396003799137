import { t } from 'i18next'
import { type Component } from 'solid-js'
import { type CogsAttribution } from '../../features/product/domain/models/cogs-management'
import { type Position } from '../../shared/helpers/dom.functions'
import printify from '../../assets/images/printify.svg'
import printful from '../../assets/images/printful.svg'
import profittree from '../../assets/images/profittree-small.svg'
import MiniExclamationCircle from '../../assets/heroicons/MiniExclamationCircle'

export interface OrdersBreakdownTooltipProps {
  readonly attribution: CogsAttribution
  readonly position?: Position
}

const OrdersBreakdownTooltip: Component<OrdersBreakdownTooltipProps> = (props) => {
  const total = Object.values(props.attribution).reduce((acc, value) => acc + value, 0)

  return (
    <div
      class="flex flex-col gap-2 border-[1px] border-gray-200 w-[236px] text-gray-900 text-sm absolute bg-white p-4 shadow-lg rounded-lg break-words z-50"
      style={{
        left: `${props.position?.left ?? 0}px`,
        top: `${props.position?.top ?? 24}px`
      }}>
      <span class="text-gray-900 font-semibold">
        {t('ls_cogs_management_attribution_title')}
      </span>
      <ul class="flex flex-col gap-2">
        <li class="flex justify-between">
          <div class="flex items-center gap-2">
            <img src={printify}/>
            {t('ls_cogs_management_attribution_printify')}
          </div>
          <span>{props.attribution.printify}</span>
        </li>
        <li class="flex justify-between">
          <div class="flex items-center gap-2">
            <img src={printful}/>
            {t('ls_cogs_management_attribution_printful')}
          </div>
          <span>{props.attribution.printful}</span>
        </li>
        <li class="flex justify-between">
          <div class="flex items-center gap-2">
            <img src={profittree}/>
            {t('ls_cogs_management_attribution_profittree')}
          </div>
          <span>{props.attribution.profittree}</span>
        </li>
        <li class="flex justify-between">
          <div class="flex items-center gap-2">
            <div class="min-w-5 h-5 bg-red-500 rounded-sm flex items-center justify-center text-white">
              <MiniExclamationCircle/>
            </div>
            {t('ls_cogs_management_attribution_missing')}
          </div>
          <span>{props.attribution.missing}</span>
        </li>
      </ul>
      <div class="h-px w-full bg-gray-200"/>
      <div class="flex justify-between text-gray-900 font-semibold">
        <span>{t('ls_cogs_management_attribution_total')}</span>
        <span>{total}</span>
      </div>
    </div>
  )
}

export default OrdersBreakdownTooltip
