import { Trans } from '@mbarzda/solid-i18next'
import { type Component, createSignal, Show } from 'solid-js'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import Button, { ButtonStyle } from '../shared/components/Button'
import MiniInformationCircle from '../../assets/heroicons/MiniInformationCircle'
import { t } from 'i18next'
import MiniExclamationTriangle from '../../assets/heroicons/MiniExclamationTriangle'
import { type CogsManagement } from '../../features/product/domain/models/cogs-management'
import { useShop } from '../../shared/providers/shop.provider'
import { MainModule } from '../../features/main-module'

export interface UpdateCogsDialogProps {
  readonly cancel: () => void
  readonly cogsUpdated: () => void
  readonly cogsList: CogsManagement[]
}

const UpdateCogsDialog: Component<UpdateCogsDialogProps> = (props) => {
  let inputRef: HTMLInputElement | undefined
  const [amountError, setAmountError] = createSignal<string>('')
  const { selectedShopId } = useShop()
  const updateCogs = MainModule.getProductComponent().provideUpdateCogs()

  const isBulk = props.cogsList.length > 1

  const validateCogs = (value?: number): boolean => {
    if (value === undefined) {
      setAmountError('empty')
      return false
    }

    if (isNaN(value) || value < 0) {
      setAmountError('invalid_cogs_number')
      return false
    }

    setAmountError('')
    return true
  }

  const handleSubmit = async (event: Event): Promise<void> => {
    event.preventDefault()
    if (!inputRef) return

    const value = Number(inputRef.value)
    const isValid = validateCogs(value)

    if (!isValid || isNaN(value)) {
      return
    }

    const shopId = selectedShopId()
    if (!shopId) return
    const cogsToUpdate = props.cogsList.map(c => c.product.id)
    await updateCogs.execute(value, cogsToUpdate, shopId)
    props.cogsUpdated()
  }

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      e.preventDefault()
      void handleSubmit(e)
    }
  }

  const onUseLatestCogs = (event: MouseEvent): void => {
    event.preventDefault()
    if (inputRef && props.cogsList[0].lastAttributedCogs !== undefined) {
      inputRef.value = props.cogsList[0].lastAttributedCogs.toString()
    }
  }

  return (
    <Dialog maxWidth={'564px'}>
      <div class="p-6 text-gray-800 flex flex-col gap-6">
        <span class="text-base font-bold"><Trans key="ls_cogs_edit_title"/></span>

        <Show when={isBulk}>
          <div class="flex flex-col w-full gap-4 rounded-md bg-blue_gray-200 text-sm py-3 px-4">
            <div class="w-full flex gap-2 text-gray-900">
              <span class=""><MiniInformationCircle/></span>
              <div class="flex flex-col">
                <span class="font-bold">
                  <Trans key={'ls_cogs_edit_bulk_title'}/>
                </span>
                <Trans key={'ls_cogs_edit_bulk_description'}/>
              </div>
            </div>
            <div class="text-gray-600 flex flex-col gap-2">
              <span class="font-bold"><Trans key={'ls_cogs_edit_bulk_info_title'}/></span>
              <ul class="list-disc pl-5">
                <li innerHTML={t('ls_cogs_edit_bulk_info_line1')}/>
                <li innerHTML={t('ls_cogs_edit_bulk_info_line2')}/>
                <li innerHTML={t('ls_cogs_edit_bulk_info_line3')}/>
              </ul>
            </div>
            <span class="font-bold text-red-500 text-xs flex gap-1 items-center">
              <span class="mt-0.5"><MiniExclamationTriangle size={3}/></span>
              <Trans key={'ls_cogs_edit_bulk_info_alert'}/>
            </span>
          </div>
        </Show>

        <form class="flex flex-col gap-6" onKeyDown={handleKeyDown}>
          <div>
            <label for="price" class="block text-sm leading-6 text-gray-900 mb-1">
              <Trans key="ls_cogs_costs"/>
            </label>
            <div class="flex items-center gap-2">
              <div class="relative rounded-md shadow-sm grow">
                <input ref={inputRef} type="number" name="price"
                       min={0}
                       step={0.01}
                       onInput={(e) => {
                         const newValue = Number((e.target as HTMLInputElement).value)
                         validateCogs(newValue)
                       }}
                       class="block w-full h-9 !text-sm !rounded-lg !border-gray-300 py-1.5 !pr-7 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:!ring-inset focus:!ring-japanese-700"
                       placeholder="0.00" aria-describedby="price-currency"/>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span class="text-gray-700 sm:text-base">$</span>
                </div>
              </div>
              <Show when={!isBulk && props.cogsList[0].lastAttributedCogs !== undefined}>
                <Button style={ButtonStyle.White} action={onUseLatestCogs}>
                  <Trans key={'ls_cogs_edit_use_latest'}/>
                </Button>
              </Show>
            </div>
            {amountError() &&
              <span class="text-red-600 text-xs"><Trans key={`ls_input_error_${amountError()}`}/></span>}
          </div>
        </form>

        <Show when={!isBulk && props.cogsList[0].attributions.missing > 0}>
          <div class="w-full rounded-md bg-blue_gray-200 flex gap-2 text-gray-900 py-3 px-4 text-sm">
            <span class=""><MiniInformationCircle/></span>
            <Trans key={'ls_cogs_edit_info'}/>
          </div>
        </Show>
      </div>

      <DialogActions>
        <div class="flex justify-between items-center w-full">
          <div class="flex gap-4 ml-auto">
            <Button action={props.cancel} style={ButtonStyle.White}>
            <Trans key="ls_generic_cancel"/>
            </Button>
            <Button style={ButtonStyle.Primary}
              action={(e) => {
                void handleSubmit(e)
              }}>
              <Trans key="ls_generic_update"/>
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateCogsDialog
