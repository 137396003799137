import { type Component, createEffect, createSignal, type JSX, Show } from 'solid-js'
import { Outlet, useLocation } from '@solidjs/router'
import Sidebar from '../sidebar/Sidebar'
import Topbar from '../topbar/Topbar'
import Footer from './Footer'
import { useError } from '../../shared/providers/error.provider'
import LoadingError from '../LoadingError'
import { useShop } from '../../shared/providers/shop.provider'
import NoEtsyShopError from '../NoEtsyShopError'
import { AppRoutes } from '../../shared/app-routes'
import FetchingShopDataBanner from '../shared/components/FetchingShopDataBanner'
import { createStore } from 'solid-js/store'
import { type Shop, ShopType } from '../../features/shop/domain/models/shop'
import ReconnectionNeededBanner from '../shared/components/ReconnectionNeededBanner'
import RefetchingShopDataBanner from '../shared/components/RefetchingShopDataBanner'

const MainLayout: Component = () => {
  const [isSidebarOpen, setSidebarOpen] = createSignal(false)
  const [needsReconnection, setNeedsReconnection] = createStore<Record<ShopType, boolean>>({
    etsy: false,
    printful: false,
    printify: false
  })

  const { error } = useError()
  const location = useLocation()

  const { selectedShop } = useShop()

  const updateNeededReconnections = (shop: Shop): void => {
    setNeedsReconnection({
      etsy: shop.invalidToken ?? false,
      printful: shop.integrations.printful?.invalidToken ?? false,
      printify: shop.integrations.printify?.invalidToken ?? false
    })
  }

  const isAnyReconnectionNeeded = (): boolean => {
    return needsReconnection.etsy || needsReconnection.printful || needsReconnection.printify
  }

  createEffect(() => {
    const shop = selectedShop()
    if (!shop?.id) return
    updateNeededReconnections(shop)
  })

  const content = (): JSX.Element => {
    return error()
      ? <LoadingError />
      : canAccessToContent()
        ? <Outlet />
        : <NoEtsyShopError />
  }

  const isTimeSelectorHidden = (): boolean => {
    return !selectedShop()?.id ||
      location.pathname.includes(AppRoutes.CogsManagement()) ||
      location.pathname.includes(AppRoutes.Integrations()) ||
      location.pathname.includes(AppRoutes.AccountSettings()) ||
      location.pathname.includes(AppRoutes.Settings()) ||
      location.pathname.includes(AppRoutes.Education())
  }

  const canAccessToContent = (): boolean => {
    return !!selectedShop()?.id ||
      location.pathname.includes(AppRoutes.AccountSettings()) ||
      location.pathname.includes(AppRoutes.Settings()) ||
      location.pathname.includes(AppRoutes.Education())
  }

  return (
    <div class="h-[100svh]">
      <Sidebar isOpen={isSidebarOpen()} onSidebarClose={() => setSidebarOpen(false)}/>
      <div class="flex flex-col lg:pl-72 h-full">
        <div class="border-b border-gray-200 shadow-b-sm">
          <Topbar onSidebarOpen={() => setSidebarOpen(true)} hidden={isTimeSelectorHidden()} />
        </div>
        <div><FetchingShopDataBanner /></div>
        <div><RefetchingShopDataBanner /></div>
        <Show when={isAnyReconnectionNeeded()}>
          <div class="px-4 sm:px-6 lg:px-10 pt-6 bg-blue_gray-100">
            <Show when={needsReconnection.etsy}>
              <ReconnectionNeededBanner shopType={ShopType.Etsy}/>
            </Show>
            <Show when={needsReconnection.printful}>
              <ReconnectionNeededBanner shopType={ShopType.Printful}/>
            </Show>
            <Show when={needsReconnection.printify}>
              <ReconnectionNeededBanner shopType={ShopType.Printify}/>
            </Show>
          </div>
        </Show>

        <main class={`${isAnyReconnectionNeeded() ? 'pt-0' : 'pt-6'} pb-16 flex-1 bg-blue_gray-100`}>
          <div class="px-4 sm:px-6 lg:px-10 h-full">
            { content() }
          </div>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default MainLayout
