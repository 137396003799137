import { type Component } from 'solid-js'

export interface ProgressBarProps {
  readonly progress: number
}

const ProgressBar: Component<ProgressBarProps> = (props) => {
  return (
    <div class="flex-1 bg-gray-200 rounded-full h-3 overflow-hidden">
      <div class="h-full bg-forest_green-500 rounded-full transition-all duration-300" style={{ width: `${props.progress}%` }}/>
    </div>
  )
}

export default ProgressBar
