import {
  type Accessor,
  type Component,
  createEffect,
  createSignal,
  Match, onCleanup,
  Show,
  Switch
} from 'solid-js'
import MiniEtsy from '../../assets/heroicons/MiniEtsy'
import MiniExclamationCircle from '../../assets/heroicons/MiniExclamationCircle'
import MiniQrCode from '../../assets/heroicons/MiniQrCode'
import MiniTag from '../../assets/heroicons/MiniTag'
import LottieAnimation from '../shared/components/LottieAnimation'
import logoAnimation from '../../assets/animations/logo-animated.json'
import { Trans } from '@mbarzda/solid-i18next'
import type { Product } from '../../features/product/domain/models/product'
import { useMeasureComponent } from '../shared/functions/use-measure'
import ListingListTooltip from '../products/ListingListTooltip'
import {
  addComponentToDOM,
  calculateTooltipPosition,
  type Size
} from '../../shared/helpers/dom.functions'
import { clickOutside } from '../../shared/directives/click-outside'
import { CogsType } from '../../features/product/domain/models/cogs'
import Printful from '../../assets/images/printful.svg'
import Printify from '../../assets/images/printify.svg'
import NoListing from '../../assets/images/no-listing-lg.svg'
import RectangleStackSolid from '../../assets/heroicons/RectangleStackSolid'

export interface SkuSummaryBoxProps {
  readonly product?: Product
  readonly numListings?: number
}

const SkuSummaryBox: Component<SkuSummaryBoxProps> = (props) => {
  const [isTooltipOpen, setIsTooltipOpen] = createSignal(false)

  let buttonRef: HTMLElement | null = null
  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  createEffect(() => {
    if (props.product && props.numListings && buttonRef) {
      tooltipSize = useMeasureComponent(<ListingListTooltip listing={props.product.listing} numListings={props.numListings} />)
    }
  })

  const showTooltip = (): void => {
    if (!props.product || !buttonRef || !tooltipSize?.()) return

    const elementRect = buttonRef.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <ListingListTooltip listing={props.product!.listing} numListings={props.numListings!} position={position}/>
    )
    setIsTooltipOpen(true)
  }

  const hideTooltip = (): void => {
    if (!isTooltipOpen()) return
    removeTooltip()
    removeTooltip = () => {}
    setIsTooltipOpen(false)
  }

  onCleanup(hideTooltip)

  const onListingsClick = (): void => {
    if (props.numListings === 1) {
      window.open(props.product!.listing.url, '_blank')
      return
    }

    if (isTooltipOpen()) {
      hideTooltip()
    } else {
      showTooltip()
    }
  }

  const listingImage = (product: Product): string => product.isListingDeleted() ? NoListing : product.listing.imgUrls.lg
  const listingTitle = (product: Product): string => product.isListingDeleted() ? 'ls_listing_deleted' : product.listing.title

  return (
    <div class="relative bg-blue_gray-200 rounded-2xl p-4 min-h-[160px]">
      <Show when={props.product} fallback={
        <div class="absolute top-[64px] left-1/2 border-none">
          <LottieAnimation animationData={logoAnimation} width="32px"/>
        </div>
      }>{(product) => (
        <div class="flex flex-col sm:flex-row gap-4 sm:gap-8">
          <div class="w-full aspect-square relative rounded-lg sm:min-w-[120px] sm:w-[120px] sm:h-[120px] overflow-hidden">
            <img class="h-full w-full object-cover" src={listingImage(product())}/>
            <Switch>
              <Match when={props.product?.cogsType === CogsType.Printful}>
                <img class="h-6 w-6 absolute bottom-1 right-1 rounded-md" src={Printful} alt="Printful"/>
              </Match>
              <Match when={props.product?.cogsType === CogsType.Printify}>
                <img class="h-6 w-6 absolute bottom-1 right-1 rounded-md" src={Printify} alt="Printify"/>
              </Match>
              <Match when={props.product?.cogsType === CogsType.None && props.numListings && props.numListings > 1}>
                <div class="absolute bottom-1 right-1 text-white bg-gray-900 p-1.5 rounded-md">
                  <RectangleStackSolid size={4}/>
                </div>
              </Match>
            </Switch>
          </div>
          <div class="flex flex-col flex-grow gap-8 sm:gap-4">
            <div
              class="flex flex-col items-start gap-8 sm:gap-4 sm:flex-row sm:justify-between sm:items-center">
              <div class="text-gray-900 flex gap-2 items-center text-xl font-semibold">
                <Show when={product().sku} fallback={
                  <>
                    <span class="text-orange-500"><MiniExclamationCircle size={6}/></span>
                    <Trans key="ls_shared_wo_sku"/>
                  </>
                }>{(sku) => (
                  <><MiniQrCode size={6}/>{sku()}</>
                )}</Show>
              </div>
              <div class="w-full sm:w-fit">
                <button ref={el => {
                  buttonRef = el
                  clickOutside(el, hideTooltip)
                }}
                   onClick={ onListingsClick }
                   class="btn btn--etsy">
                  <MiniEtsy size={4}/>
                  <Trans key="ls_shared_view_on_etsy"/>
                </button>
              </div>
            </div>

            <div class="text-base">
              <Show when={props.numListings && props.numListings > 1}>
                <p class="text-gray-500 m-0"><Trans key="ls_products_multiple_listings"/></p>
              </Show>
              <p class={`${product().isListingDeleted() ? 'text-gray-500' : 'text-gray-900'} m-0`}><Trans key={listingTitle(product())} /></p>
            </div>
            <Show when={product().variantName}>{(name) => (
              <div class="flex items-center text-gray-500 gap-1">
                <MiniTag size={4}/><span>{name()}</span>
              </div>
            )}</Show>
          </div>
        </div>
      )}</Show>
    </div>
  )
}

export default SkuSummaryBox
