import type { Component } from 'solid-js'
import { Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { type Product } from '../../features/product/domain/models/product'
import MiniEtsy from '../../assets/heroicons/MiniEtsy'
import MiniExclamationCircle from '../../assets/heroicons/MiniExclamationCircle'

export interface CogsManagementSkuCellProps {
  readonly product: Product
  readonly numListings: number
}

const CogsManagementSkuCell: Component<CogsManagementSkuCellProps> = (props) => {
  return (
    <>
      <div class="mb-1">{props.product.sku}</div>
      <Show when={!props.product.sku}>
        <div class="mb-1 flex items-center gap-1">
          <span class="text-orange-500"><MiniExclamationCircle /></span><Trans key="ls_shared_wo_sku" />
        </div>
      </Show>
      <div class="text-xs text-gray-500">
        <Show when={props.numListings === 1}>
          <a href={props.product.listing.url} target="_blank" class="flex items-center gap-1">
            <MiniEtsy size={3}/><Trans key="ls_shared_view_on_etsy" />
          </a>
        </Show>
        <Show when={props.numListings > 1}>
          <span>{props.numListings} <Trans key="ls_cogs_management_listings" /></span>
        </Show>
      </div>
    </>
  )
}

export default CogsManagementSkuCell
