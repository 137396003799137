import { ImportCogsManagementQuery } from '../../data/queries/import-cogs-management.query'
import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'

export class ImportCogsManagementInteractor {
  constructor(
    private readonly importCogs: PutInteractor<string>
  ) {
  }

  public async execute (
    shopId: number,
    file: File
  ): Promise<string> {
    return await this.importCogs.execute(undefined, new ImportCogsManagementQuery(shopId, file))
  }
}
