import { type Component, createEffect, createSignal } from 'solid-js'
import MultiSelectButton from '../shared/components/MultiSelectButton'
import PeriodFilter from './PeriodFilter'
import { type PeriodType } from '../../features/shared/period-type'

export interface CogsManagementFilters {
  productType?: string[]
  cogsAttribution?: string[]
  period?: { from: number, to: number }
  isPersonalizedActive?: boolean
  isCogsIssuesActive?: boolean
}

const ProductTypeOptions = {
  physicalItem: 'physical',
  digitalFiles: 'digital'
}

const CogsAttributionOptions = {
  profitTree: 'profittree',
  printify: 'printify',
  printful: 'printful'
}

interface SoundDesignBoxListFiltersProps {
  filters: CogsManagementFilters
  setFilters: (
    filters:
    | CogsManagementFilters
    | ((prevFilters: CogsManagementFilters) => CogsManagementFilters)
  ) => void
  forcedPeriod?: PeriodType
}

const CogsManagementTableFilters: Component<SoundDesignBoxListFiltersProps> = (props) => {
  const [filters, setFilters] = createSignal<CogsManagementFilters>(props.filters ?? {
    productType: [],
    cogsAttribution: [],
    isPersonalizedActive: false,
    isCogsIssuesActive: false
  })

  createEffect(() => {
    setFilters(props.filters)
  })

  const handleFilterChange =
    (newFilter: CogsManagementFilters): void => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilter
      }))
      props.setFilters(filters())
    }

  return (
    <div class="gap-4 items-center flex flex-col md:flex-row lg:flex-col xl:flex-row">
      <div class="flex w-full items-center gap-4 flex-col sm:flex-row">
        <PeriodFilter
          selectedPeriod={props.forcedPeriod}
          setSelectedPeriod={
          (period) => {
            handleFilterChange({ period })
          }
        }/>
        <MultiSelectButton
          key="ls_cogs_management_filter_product_type"
          options={ProductTypeOptions}
          value={props.filters.productType}
          setSelectedOptions={
            (selected) => {
              handleFilterChange({ productType: selected })
            }
          }/>
      </div>
      <div class="flex w-full items-center gap-4 flex-col sm:flex-row">
        <div class="w-full sm:max-w-[calc(50%-8px)] xl:max-w-none">
          <MultiSelectButton
            key="ls_cogs_management_filter_cogs"
            options={CogsAttributionOptions}
            value={props.filters.cogsAttribution}
            setSelectedOptions={
              (selected) => {
                handleFilterChange({ cogsAttribution: selected })
              }
            }/>
        </div>
        <div class="flex items-center gap-4">
        <div class="flex items-center gap-2 text-sm">
          <input
            type="checkbox"
            checked={props.filters.isPersonalizedActive}
            class="form-checkbox h-4 w-4 text-japanese-600 transition duration-150 ease-in-out rounded-[4px] border-gray-300"
            onChange={(e) => {
              handleFilterChange({ isPersonalizedActive: e.currentTarget.checked })
            }}
          />
          <span>Personalized</span>
        </div>
        <div class="flex items-center gap-2 text-sm">
          <input
            type="checkbox"
            checked={props.filters.isCogsIssuesActive}
            class="form-checkbox h-4 w-4 text-japanese-600 transition duration-150 ease-in-out rounded-[4px] border-gray-300"
            onChange={(e) => {
              handleFilterChange({ isCogsIssuesActive: e.currentTarget.checked })
            }}
          />
          <span class="whitespace-nowrap">Cogs Issues</span>
        </div>
        </div>
      </div>
    </div>
  )
}

export default CogsManagementTableFilters
