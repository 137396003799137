import {
  createContext,
  useContext,
  type JSX,
  createSignal,
  type Accessor,
  type Setter
} from 'solid-js'
import { CheckoutEventNames, type CheckoutOpenOptions, type Paddle, type PaddleEventData } from '@paddle/paddle-js'
import { useTracking } from './tracking.provider'
import { type User } from '../../features/auth/domain/models/user'

interface PaddleContextType {
  readonly paddle: Accessor<Paddle | undefined>
  readonly setPaddle: Setter<Paddle | undefined>
  readonly checkoutCallback: (data: PaddleEventData, offeringName: string, onCompletedCallback: () => void) => void
  readonly openCheckout: (user: User, offeringId: string) => CheckoutOpenOptions
}

const [paddle, setPaddle] = createSignal<Paddle | undefined>()

const defaultContextValue: PaddleContextType = {
  paddle,
  setPaddle,
  checkoutCallback: async () => {
    console.warn('checkoutCallback llamado fuera del PaddleProvider')
  },
  openCheckout: (user: User, offeringId: string): CheckoutOpenOptions => ({
    customer: {
      id: user.customerId
    },
    items: [{ priceId: offeringId, quantity: 1 }],
    customData: {
      email: user.email,
      fp_tid: window.FPROM?.data.tid ?? ''
    }
  })
}

const PaddleContext = createContext<PaddleContextType>(defaultContextValue)

export const PaddleProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { trackEvent } = useTracking()

  const contextValue: PaddleContextType = {
    paddle,
    setPaddle,
    checkoutCallback: async (data: PaddleEventData, offeringName: string, onCompletedCallback: () => void): Promise<void> => {
      if (data.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
        setTimeout(async () => {
          trackEvent('Purchase', { completed: true, product: offeringName })
          paddle()?.Checkout.close()
          onCompletedCallback()
        }, 1000)
      } else if (data.name) {
        trackEvent('Purchase', { completed: false, reason: data.name })
      }
    },
    openCheckout: (user: User, offeringId: string): CheckoutOpenOptions => ({
      customer: {
        id: user.customerId
      },
      items: [{ priceId: offeringId, quantity: 1 }],
      customData: {
        email: user.email,
        fp_tid: window.FPROM?.data.tid ?? ''
      }
    })
  }

  return (
    <PaddleContext.Provider value={contextValue}>
      {props.children}
    </PaddleContext.Provider>
  )
}

export const usePaddle = (): PaddleContextType => {
  return useContext(PaddleContext)
}
