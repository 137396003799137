import { type Component, createSignal, For, Match, Show, Switch } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { MainModule } from '../../features/main-module'
import { useShop } from '../../shared/providers/shop.provider'
import LottieAnimation from '../shared/components/LottieAnimation'
import logoAnimation from '../../assets/animations/logo-animated.json'
import issue from '../../assets/images/issue.png'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import DocumentArrowUp from '../../assets/heroicons/DocumentArrowUp'
import { t } from 'i18next'
import { getEnumKeyByValue } from '../../shared/helpers/get-enum-key-by-value'
import {
  CogsBulkUploadErrorCode
} from '../../features/product/data/entities/cogs-bulk-upload-error-code'
import { useTracking } from '../../shared/providers/tracking.provider'
import Button, { ButtonStyle } from '../shared/components/Button'

export interface CogsBulkUploadDialogProps {
  readonly cancel: () => void
  readonly uploaded: () => void
}

interface CogsBulkUploadError {
  readonly code: CogsBulkUploadErrorCode
  readonly message?: string
}

const CogsBulkUploadDialog: Component<CogsBulkUploadDialogProps> = (props) => {
  const [isUploading, setIsUploading] = createSignal(false)
  const [uploadError, setUploadError] = createSignal<CogsBulkUploadError | undefined>()
  const { selectedShopId } = useShop()
  const { trackEvent } = useTracking()

  const importCogs = MainModule.getProductComponent().provideImportCogsManagement()

  let inputFile: HTMLInputElement

  const onUploadButtonClick = (): void => {
    inputFile.click()
  }

  const handleFileChange = async (event: Event): Promise<void> => {
    const target = event.target as HTMLInputElement
    const file = target.files?.length ? target.files[0] : undefined
    if (!file) {
      return
    }

    setIsUploading(true)
    try {
      trackEvent('Cogs Bulk Update', { shopId: selectedShopId(), status: 'started' })
      await importCogs.execute(selectedShopId()!, file)
      props.uploaded()
      setIsUploading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorStatus(error.response.data as CogsBulkUploadError)
    }
  }

  const setErrorStatus = (error?: CogsBulkUploadError): void => {
    setUploadError(error)
    setIsUploading(false)
  }

  const duplicatedSkusArray = (message: string): string[] => message.split(', ')
  return (
    <Dialog maxWidth={'616px'}>
      <div class="p-6 text-black flex gap-8 lg:gap-2 flex-col lg:flex-row">
        <Show when={isUploading()}>
          <div class="flex flex-col items-center w-full py-2 gap-2">
            <LottieAnimation animationData={logoAnimation} width="40px"/>
            <span class="text-xl font-semibold"><Trans key="ls_cogs_bulk_processing"/></span>
            <span class="text-sm"><Trans key="ls_cogs_bulk_take_minutes"/></span>
          </div>
        </Show>
        <Show when={uploadError()}>{(error) => (
          <div class="flex flex-col items-center mx-auto text-center py-6 gap-2">
            <img src={issue} class="h-24" alt="error"/>
            <span class="text-base text-gray-900 font-bold"><Trans key="ls_cogs_bulk_couldnt_import"/></span>
            <Switch fallback={
              <span class="text-sm text-gray-500 max-w-[330px]">
                <Trans key={`ls_cogs_bulk_error_${getEnumKeyByValue(CogsBulkUploadErrorCode, error().code)}`} />
              </span>
            }>
              <Match when={error().code === CogsBulkUploadErrorCode.Unknown}>
                <span class="text-sm text-gray-500 max-w-[330px]"><Trans key="ls_cogs_bulk_make_sure"/></span>
              </Match>
              <Match when={error().code === CogsBulkUploadErrorCode.DuplicateSkuCogs}>
                <div class="flex flex-col items-center text-sm text-gray-500">
                  <span><Trans key="ls_cogs_bulk_error_DuplicateSkuCogs"/></span>
                  <div class="flex flex-wrap justify-center max-w-[420px] text-gray-900">
                    <For each={duplicatedSkusArray(error().message!)}>
                      {(sku, index) => (
                        <span class="whitespace-nowrap">
                          {sku}{index() < duplicatedSkusArray(error().message!).length - 1 ? ', ' : ''}
                        </span>
                      )}
                    </For>
                  </div>
                </div>
                <span class="text-sm text-gray-500">
                  <Trans key="ls_cogs_bulk_error_check_duplicates"/>
                </span>
              </Match>
            </Switch>
          </div>
        )}</Show>
        <Show when={!isUploading() && !uploadError()}>
          <div class="flex flex-col gap-6 w-full">
            <div class="flex flex-col gap-2">
              <span class="text-xl text-gray-900 font-semibold"><Trans key="ls_cogs_bulk_using_template"/></span>
              <span innerHTML={t('ls_cogs_bulk_simple_steps')} />
            </div>
            <div class="flex flex-col gap-4">
              <For each={Array(5)}>{(_item, idx) => (
                <div class="flex gap-4 items-start min-h-9">
                  <div class="flex min-w-6 h-6 rounded-full bg-gray-500 text-white text-xs items-center justify-center">
                    {idx() + 1}
                  </div>
                  <div class="flex flex-col items-start gap-1">
                    <span innerHTML={t(`ls_cogs_bulk_instruction_${idx() + 1}`)}/>
                    <p class="text-sm leading-5 font-normal text-gray-500" innerHTML={t(`ls_cogs_bulk_subtext_${idx() + 1}`)} />
                  </div>
                </div>
              )}</For>
            </div>
          </div>
          {/*
          <div class="w-full lg:w-1/2 flex flex-col gap-4">
            <span class="text-xl font-semibold"><Trans key="ls_cogs_bulk_watch_tutorial"/></span>
               <div class="h-0 overflow-hidden pb-[56.25%] pt-[30px] relative">
                 <iframe class="h-full absolute left-0 top-0 w-full border-none"
                         src="https://www.youtube.com/embed/D5T4UT3U4lc?si=g88OyFwpkJxPpjd7"
                         title="3 Ways To Set Up Your Etsy Product Costs In ProfitTree"
                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" />
            </div>
          </div>
          */}
        </Show>
      </div>
      <Show when={!isUploading() && !uploadError()}>
        <DialogActions>
          <Button action={props.cancel} style={ButtonStyle.White}>
            <Trans key="ls_generic_cancel"/>
          </Button>
          <Button action={onUploadButtonClick} style={ButtonStyle.Primary}>
            <DocumentArrowUp size={4}/>
            <Trans key="ls_cogs_bulk_upload_template"/>
          </Button>
          <input type="file" accept=".csv" ref={(el) => { inputFile = el }} style={{ display: 'none' }} onChange={handleFileChange}/>
        </DialogActions>
      </Show>
      <Show when={uploadError()}>
        <DialogActions>
          <Button action={props.cancel} style={ButtonStyle.White}>
            <Trans key="ls_generic_cancel"/>
          </Button>
          <Button action={ () => { setUploadError(undefined) } } style={ButtonStyle.Secondary}>
              <Trans key='ls_generic_try_again'/>
          </Button>
        </DialogActions>
      </Show>
    </Dialog>
  )
}

export default CogsBulkUploadDialog
