export enum PeriodType {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  MonthToDate = 'monthToDate',
  CurrentMonth = 'currentMonth',
  LastMonth = 'lastMonth',
  QuarterToDate = 'quarterToDate',
  CurrentQuarter = 'currentQuarter',
  LastQuarter = 'lastQuarter',
  YearToDate = 'yearToDate',
  CurrentYear = 'currentYear',
  LastYear = 'lastYear',
  AllTime = 'allTime',
  Custom = 'custom'
}

export const SelectablePeriods = [
  PeriodType.Today,
  PeriodType.Yesterday,
  PeriodType.Last7Days,
  PeriodType.Last30Days,
  PeriodType.MonthToDate,
  PeriodType.LastMonth,
  PeriodType.QuarterToDate,
  PeriodType.LastQuarter,
  PeriodType.YearToDate,
  PeriodType.LastYear
]
