import { type Component, createEffect, createSignal, Show } from 'solid-js'
import StatsGroup from '../shared/components/StatsGroup'
import { type KPI, KPIType } from '../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import { KPIGroupId } from '../../shared/models/kpi-group'
import MainStats from '../shared/components/MainStats'
import FinancialBreakdownTable from './FinancialBreakdownTable'
import { getKPIsInGroup, getKPIVMsInGroup } from '../shared/functions/kpi.functions'
import FinancialBreakdownChart from './FinancialBreakdownChart'
import SparklesSolid from '../../assets/heroicons/SparklesSolid'
import ChartPieSolid from '../../assets/heroicons/ChartPieSolid'
import { MainModule } from '../../features/main-module'
import { useShop } from '../../shared/providers/shop.provider'
import { usePeriods } from '../../shared/providers/period.provider'
import { useError } from '../../shared/providers/error.provider'
import LottieAnimation from '../shared/components/LottieAnimation'
import logoAnimation from '../../assets/animations/logo-animated.json'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import MainMissingCogsBanner from './MainMissingCogsBanner'
import { useLocation, useNavigate } from '@solidjs/router'
import { AnalyticsConfig } from '../shared/analytics.vm'
import AnalyticsChart from '../shared/components/AnalyticsChart'
import LockedPeriodDialog from '../shared/components/LockedPeriodDialog'
import { useUser } from '../../shared/providers/user-provider'
import { type CogsSummary } from '../../features/product/domain/models/cogs-summary'
import { AppRoutes } from '../../shared/app-routes'

const Dashboard: Component = () => {
  const [selectedKpiType, setSelectedKpiType] = createSignal(KPIType.OperatingProfit)
  const [selectedKpi, setSelectedKpi] = createSignal<KPI>()
  const [kpis, setKpis] = createSignal<KPI[]>([])
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [cogsSummary, setCogsSummary] = createSignal<CogsSummary | undefined>(undefined)

  const navigate = useNavigate()
  const { selectedShop } = useShop()
  const { periods, isProPeriod } = usePeriods()
  const { setError } = useError()
  const { userPurchases } = useUser()

  const getCogsSummary = MainModule.getProductComponent().provideGetCogsSummary()
  const analyticsComponent = MainModule.getAnalyticsComponent()
  const getTimeseries = analyticsComponent.provideGetAnalyticsTimeseries()
  const getDashboardKPIs = analyticsComponent.provideGetAnalytics()

  const location = useLocation()

  createEffect((): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    void getCogsSummary.execute(shop.id, periods().current.from, periods().current.to).then((summary) => {
      setCogsSummary(summary)
    })

    executeWithUrlScope(
      async () => {
        await getDashboardKPIs.execute(shop.id, periods())
          .then((analytics) => {
            setKpis(analytics.kpis)
          })
      }, location
    )
      .catch((error) => {
        console.error(error)
        setError(true)
      }).finally(() => {
        setIsLoading(false)
      })
  })

  createEffect((): void => {
    setSelectedKpi(
      kpis().find((KPI) => KPI.id === selectedKpiType())!
    )
  })

  const onFixCogs = (): void => {
    navigate(`${AppRoutes.CogsManagement()}?period=${periods()?.type}`)
  }

  return (
    <div id="dashboard" class="relative">
      <Show when={userPurchases()}>{(purchases) => (
        <Show when={isProPeriod() && !purchases().isPro}>
          <LockedPeriodDialog />
        </Show>
      )}</Show>
      <Show when={selectedShop()?.isFullyFetched && cogsSummary()}>{(summary) => (
        <div class="mb-6">
          <MainMissingCogsBanner summary={summary()} action={onFixCogs} simplified/>
        </div>
      )}</Show>
      <h1 class="pb-4 page-title"><SparklesSolid /><Trans key="ls_dashboard_section_insights"/></h1>
      <div class="grid gap-9 content-center">
        <MainStats kpis={ getKPIVMsInGroup(kpis(), KPIGroupId.Main) } selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType} />
        <Show when={!isLoading() && selectedKpi()}>{(kpi) =>
          <AnalyticsChart kpi={kpi()} getTimeseriesAction={getTimeseries}/>
        }</Show>
        <Show when={isLoading() || !selectedKpi()}>
          <div class="w-full h-[421px] rounded-lg bg-white flex items-center justify-center shadow">
            <LottieAnimation animationData={logoAnimation} width="2rem" />
          </div>
        </Show>
        <ul role="list" class="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          <li class="col-span-1">
            <StatsGroup config={AnalyticsConfig.performance!} kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Performance)} color="green" selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </li>
          <li class="col-span-1">
            <StatsGroup config={AnalyticsConfig.orders!} kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Orders)} color="blue" selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </li>
          <li class="col-span-1">
            <StatsGroup config={AnalyticsConfig.shipping!} kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Shipping)} color="orange" selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </li>
          <li class="col-span-1">
            <StatsGroup config={AnalyticsConfig.advertisement!} kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Advertisement)} color="purple" selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </li>
        </ul>
        <div>
          <h1 class="pb-4 page-title"><ChartPieSolid /><Trans key="ls_dashboard_section_fin_break"/></h1>
          <div class="overflow-hidden p-4 xs:p-0 rounded-lg bg-white shadow">
            <Show when={!isLoading() && kpis()} fallback={
              <div
                class="h-[421px] flex items-center justify-center">
                <LottieAnimation animationData={logoAnimation} width="2rem"/>
              </div>
            }>{(kpiList) =>
              <FinancialBreakdownChart kpis={getKPIsInGroup(kpiList(), KPIGroupId.FinancialChart)}/>
            }</Show>
          </div>
        </div>
        <FinancialBreakdownTable kpis={getKPIVMsInGroup(kpis(), KPIGroupId.FinancialDashboard)}/>
      </div>
    </div>
  )
}

export default Dashboard
