import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'
import { type Component } from 'solid-js'
import { type CogsUpdateReport } from '../../features/product/domain/models/cogs-update-status'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'

export interface CogsUpdateReportDialogProps {
  readonly report: CogsUpdateReport
  readonly close: () => void
}

const CogsUpdateReportDialog: Component<CogsUpdateReportDialogProps> = (props) => {
  return (
    <Dialog maxWidth={'325px'}>
      <div class="p-6 text-black flex gap-8 lg:gap-2 flex-col">
        <img src='/images/report.svg' class="h-24 mb-2" alt="error"/>
        <span class="text-base text-gray-900 font-bold">
          <Trans key="ls_cogs_bulk_import_successful"/>
        </span>
        <div class="flex flex-col text-gray-500 text-sm">
          <span innerHTML={t('ls_cogs_bulk_import_new', { amount: props.report.success })} />
          <span innerHTML={t('ls_cogs_bulk_import_sku_pod', { amount: props.report.productsPod })} />
          <span innerHTML={t('ls_cogs_bulk_import_sku_not_found', { amount: props.report.productsNotFound })} />
        </div>
      </div>
      <DialogActions>
        <button
          onClick={props.close}
          class="w-fit flex items-center px-3 py-2 justify-center rounded-md bg-white text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 duration-200">
          <Trans key="ls_generic_close"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default CogsUpdateReportDialog
