import { createEffect, createSignal, Show } from 'solid-js'
import type { Component, JSX } from 'solid-js'
import MagnifyingGlass from '../../../assets/heroicons/MagnifyingGlass'
import { useDebounce } from '../../../shared/helpers/debounce'
import { t } from 'i18next'
import XMark from '../../../assets/heroicons/XMark'

export interface SearchBarProps {
  readonly setSearchTerm: (value?: string) => void
  readonly searchTerm?: string
  readonly placeholder: string
  readonly extraFilter?: () => JSX.Element
}

const SearchBar: Component<SearchBarProps> = (props) => {
  const [searchTerm, setSearchTerm] = createSignal<string | undefined>('')
  const [placeholder, setPlaceholder] = createSignal<string | undefined>()
  const searchTermDebounced = useDebounce(setSearchTerm, 600)

  let inputRef: HTMLInputElement | null = null
  createEffect(() => {
    props.setSearchTerm(searchTerm())
  })

  createEffect(() => {
    setSearchTerm(props.searchTerm ?? '')
  })

  createEffect(() => {
    setPlaceholder(t(props.placeholder))
  })

  const clearSearch = (): void => {
    if (inputRef) {
      inputRef.value = ''
    }
    setSearchTerm(undefined)
  }

  return (
    <div class="relative rounded-md shadow-sm flex bg-white items-center pr-2 pl-1 w-full">
      <div class="pointer-events-none inset-y-0 left-0 flex items-center pl-3 text-gray-500">
        <MagnifyingGlass />
      </div>
      <input
        ref={el => (inputRef = el)}
        type="text"
        onInput={(event) => { searchTermDebounced(event.target.value) }}
        value={searchTerm()}
        class="!text-sm block h-12 !border-0 w-full py-1.5 pl-6 text-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 !focus:border-0 !ring-0 !outline-none"
        placeholder={placeholder()}
      />
      <Show when={searchTerm()}>
        <button onClick={clearSearch} class="text-gray-500">
          <XMark />
        </button>
      </Show>
      <Show when={props.extraFilter}>
        { props.extraFilter!() }
      </Show>
    </div>
  )
}

export default SearchBar
